import {useEffect, useState} from "react";
import LogoutModal from "./modal/LogoutModal";

const Clock = () => {
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");
  const [second, setSecond] = useState("00");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateClock = () => {
    const date = new Date();
    setHour(String(date.getHours()).padStart(2, "0"));
    setMinute(String(date.getMinutes()).padStart(2, "0"));
    setSecond(String(date.getSeconds()).padStart(2, "0"));
  }

  useEffect(() => {
    // 컴포넌트가 처음 렌더링될 때 즉시 시간을 업데이트
    updateClock();
    // 1초마다 시간 업데이트
    const timerId = setInterval(updateClock, 1000);

    return () => clearInterval(timerId);
  }, []);

  return (
      <>
        {isModalOpen && <LogoutModal setIsModalOpen={setIsModalOpen} />}

        <div className="bg-white text-black px-3 py-2 text-2xl font-bold flex flex-row rounded-xl shadow-md border border-gray-200"
             onClick={() => setIsModalOpen(true)}
        >
          <p className="mx-2">{hour}</p>
          :
          <p className="mx-2">{minute}</p>
          :
          <p className={`mx-2 ${second >= 51 ? 'text-red-500' : 'text-green-600'}`}>
            {second}
          </p>
        </div>
      </>

  )
}
export default Clock;