export const MAIN_PAGE = '/' // 메인
export const MAIN_TEMP_PAGE = '/main' // 메인
export const PROTO_GAME_PAGE = '/proto' // 프로토 경기분석
export const TOTO_PAGE = '/toto' // 토토
export const GAME_ANALYSIS_PAGE = '/game_analysis' // 경기 분석
export const HAPPY_PICK_PAGE = '/happy_pick' // 행복픽 페이지
export const CUSTOM_PICK_PAGE = '/custom_pick' // 커스텀픽 페이지
export const LOGIN_PAGE = '/login' // 로그인 페이지
export const ERROR_PAGE = '/error' // 에러 페이지
export const LOADING_PAGE = '/loading' // 로딩 페이지
export const SETTING_PAGE = '/setting' // 설정 페이지