import {useNavigate} from "react-router-dom";
import {PROTO_GAME_PAGE} from "../constants/page_constants";
import ColorModeToggle from "../components/ColorModeToggle";
import {useEffect, useState} from "react";
import neverLandUtils from "../utils/NeverLandUtils";
import {accountInfo, DEVICE_SETTING, DEVICE_SETTING_DEFAULT_LIST} from "../constants/constants";
import {useLocalStorage} from "../utils/useLocalStorage";
import {ModalHandler, OpenModalHandle} from "../components/modal/ModalHandler";

const SettingPage = () => {
  const [lightMode, setLightMode] = useState(DEVICE_SETTING_DEFAULT_LIST);
  const [settingList, setSettingList] = useLocalStorage(null);
  const navigate = useNavigate();

  useEffect(() => {
    // 로컬스토리지에서 가져와서 초기값 세팅
    const localSetting = neverLandUtils.commons.load(DEVICE_SETTING)

    if (localSetting) {
      setSettingList(localSetting);
      setLightMode(localSetting.dark_mode);
    }
  }, []);

  const upDateOptions = () => {
    const updatedSettings = {dark_mode: lightMode};
    setSettingList(updatedSettings);
    return updatedSettings;
  };

  const saveDeviceSettingAtLocalStorage = async () => {
    // 설정 리스트 로컬 스토리지 저장하고 새로고침
    const updatedSettings = upDateOptions();
    neverLandUtils.commons.save(DEVICE_SETTING, updatedSettings);

    window.location.reload();
  };

  const OpenModal = OpenModalHandle.create();

  return (
      <div className="w-full h-full px-10 py-40 flex flex-col justify-center">
        <ModalHandler initModalHandle={OpenModal}/>
        <div className="flex justify-end">
          <button className="button-style bg-gray-500 text-xl"
                  onClick={() => navigate(PROTO_GAME_PAGE)}>
            메인으로 돌아가기
          </button>
        </div>

        <div
            className="w-full h-[calc(100%-10rem)] flex flex-col items-center justify-center bg-white rounded-lg mt-10 border border-b-gray-300 shadow-md">
          <div className="w-full h-[50%] px-20">
            <div className="flex flex-row">
              <label className="text-2xl mr-5 bg-gray-200 py-1.5 px-4 rounded-lg">화면 모드</label>
              <div className="flex flex-row items-center">
                <label className="text-xl mr-4">라이트 모드</label>
                <ColorModeToggle lightMode={lightMode} setLightMode={setLightMode}/>
                <label className="text-xl ml-4">다크 모드</label>
              </div>
            </div>
          </div>

          <button className="button-style bg-blue-600 w-28"
                  onClick={() => OpenModal.openSelectModal("기기 설정 변경", "현재 설정으로 변경하시겠습니까?",
                      {items: [{'text': '저장', 'onClick': () => saveDeviceSettingAtLocalStorage()}]})}
          >설정 저장
          </button>
        </div>
      </div>
  )
};
export default SettingPage;