import React, {useRef, useState} from "react";
import CartSidebar from "../components/CartSidebar";
import ProtoGameCard from "../components/card/ProtoGameCard";
import {PROTO_CATEGORY_FILTER_BUTTONS_DATA} from "../constants/component_constants";
import FilterCheckBoxTable from "../components/table/FilterCheckBoxTable";
import {getBackgroundColor, isLandscapeMode} from "../utils/componentUtils";
import {ExclamationCircleIcon} from "@heroicons/react/16/solid";
import ScrollButton from "../components/ScrollButton";
import GameAnalysisModal from "../components/modal/GameAnalysisModal";
import GameAnalysisPage from "./GameAnalysisPage";
import {accountInfo} from "../constants/constants";

const ProtoGamePage = () => {
  const [categoryOption, setCategoryOptionOption] = useState("all");
  const gameCardRef = useRef(null);
  const [showGameAnalysis, setShowGameAnalysis] = useState(false);  // 경기분석 보이기 여부
  const [iFrameUrl, setIFrameUrl] = useState(null);


  return (
      <div
          className={`${isLandscapeMode() ? "justify-between pr-2" : "justify-center"} w-full flex flex-row h-[calc(100%-2rem)]`}>
        <FilterCheckBoxTable isFilterVisible={false}/>

        {(!isLandscapeMode() && showGameAnalysis) &&
            <GameAnalysisModal setShowGameAnalysis={setShowGameAnalysis} iFrameUrl={iFrameUrl}/>}

        {/* <------------ 경기정보 영역 ------------> */}
        <div className="w-4/7 h-full">
          {/* 경기 종목 선택 버튼 */}
          <div
              className={`${accountInfo.isDarkMode() ? "text-white" : ""} w-full h-[3.5rem] flex flex-row mx-1 font-bold pb-2 whitespace-nowrap`}>
            {PROTO_CATEGORY_FILTER_BUTTONS_DATA.map((item, index) => {
              return (
                  <div key={index} className="flex flex-col items-center">
                    <button
                        className={`${categoryOption === item.value ? (accountInfo.isDarkMode() ? "text-gray-900 bg-white" : "text-white bg-gray-900") + " rounded-full" : ""} 
                        text-lg px-7 mx-5 py-1`}
                        onClick={() => setCategoryOptionOption(item.value)}
                    >
                      {item.name}
                    </button>
                    <div className={`${getBackgroundColor(item.value)} w-20 h-1 mt-1`}></div>
                  </div>
              )
            })}
          </div>
          {/* 경기 정보 카드 */}
          <div className="w-full h-[calc(100%-4rem)] overflow-y-auto px-3 py-3"
               ref={gameCardRef}
          >
            <ScrollButton gameCardRef={gameCardRef}/>
            <ProtoGameCard categoryOption={categoryOption} setShowGameAnalysis={setShowGameAnalysis}
                           setIFrameUrl={setIFrameUrl}/>
          </div>
        </div>

        {/* <------------ 가로모드일 때 경기정보 띄우기 ------------> */}
        {isLandscapeMode() &&
            (showGameAnalysis ? (
                <div
                    className="w-[50rem] flex flex-col border border-gray-600 rounded-lg bg-white items-center">

                    <GameAnalysisPage iFrameUrl={iFrameUrl} setShowGameAnalysis={setShowGameAnalysis}/>
                </div>
            ) : (
                <div
                    className="w-[50rem] flex flex-col text-gray-500 justify-end pb-10 items-center border border-gray-600 rounded-lg">
                  <ExclamationCircleIcon className="h-10 w-10 mr-5 my-3"/>
                  <p className="text-2xl text-center">
                    경기를 선택해 주세요.
                  </p>
                </div>
            ))
        }

        {/* <------------ 카트사이드바 영역 ------------> */}
        <CartSidebar gameCardRef={gameCardRef}/>
      </div>
  );
}
export default ProtoGamePage;
