import {RiDeleteBack2Line} from "react-icons/ri";

const NumberKeyPad = ({handleInputValueOnChange, handleInputBlur}) => {

  const buttonData = [
    {name: "1", value: '1'},
    {name: "2", value: '2'},
    {name: "3", value: '3'},
    {name: "4", value: '4'},
    {name: "5", value: '5'},
    {name: "6", value: '6'},
    {name: "7", value: '7'},
    {name: "8", value: '8'},
    {name: "9", value: '9'},
    {name: "00", value: '00'},
    {name: "0", value: '0'},
    {name: "000", value: '000'},
  ];


  return (
      <div className="keypad-background-style flex flex-row z-50 w-[20rem] h-[22rem] p-3 rounded-lg justify-between shadow-md border border-gray-400">
        <div className="w-3/4 h-full flex flex-wrap justify-between">
          {buttonData.map((number) => {
            return (
                <button
                    className="keypad-button-style text-2xl w-16 h-16 rounded-lg m-1 shadow-lg border border-gray-400"
                    key={number.name}
                    onClick={() => handleInputValueOnChange(number.value)}
                >
                  {number.name}
                </button>
            )
          })}
        </div>

        <div className="flex flex-col justify-between">
          <button
              className="keypad-button-style w-16 h-full rounded-lg mt-1 mb-2 shadow-lg flex items-center justify-center border border-gray-400"
              key={'delete'}
              onClick={() => handleInputValueOnChange('delete')}
          >
            <RiDeleteBack2Line className="w-6 h-auto"/>
          </button>

          <button
              className="keypad-button-style text-lg w-16 h-full rounded-lg mt-1 mb-3.5 shadow-lg items-center text-center justify-center border border-gray-400"
              key={'confirm'}
              onClick={handleInputBlur}
          >
            확인
          </button>
        </div>
      </div>
  );
}
export default NumberKeyPad;