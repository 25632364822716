import {Link, useLocation, useNavigate} from "react-router-dom";
import {HEADER_MENU} from "../constants/component_constants";
import Clock from "./Clock";
import neverLandGameData from "../utils/NeverLandGameData";
import {useEffect, useState} from "react";
import {isLandscapeMode} from "../utils/componentUtils";
import LogoutModal from "./modal/LogoutModal";
import {accountInfo} from "../constants/constants";
import {PROTO_GAME_PAGE} from "../constants/page_constants";

const Header = () => {
  const [refreshDate, setRefreshDate] = useState(new Date());
  const currentLocation = useLocation();

  const updateUI = () => {
    setRefreshDate(new Date());
  }
  const handleOnChanged = () => {
    updateUI();
  };

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
    }
  }, []);

  return (
      <div
          className={`${isLandscapeMode() ? "w-full px-3" : "w-[62rem]"} ${accountInfo.isDarkMode() ? "text-white" : ""} h-[6rem] flex items-center overflow-x-auto justify-between`}>

        <div className="flex">
          {HEADER_MENU.map((menu) => {
            return (
                <Link
                    key={menu.link}
                    to={menu.link}
                    className={`${currentLocation.pathname === menu.link ? `border-2 ${accountInfo.isDarkMode() ? "border-b-gray-900" : "border-b-white"}  rounded-t-lg` : "border-b-2"}
                              ${accountInfo.isDarkMode() ? "" : "border-black"} flex items-center flex-shrink-0 justify-center mt-2 py-3 text-lg min-w-36`}>
                  <menu.icon className="w-5 h-auto"/>
                  <span>{menu.name}</span>
                </Link>
            )
          })}
        </div>

        <div className="flex flex-row items-center mt-2">
          {currentLocation.pathname === PROTO_GAME_PAGE &&
              <p className="mr-4 text-xl whitespace-nowrap">
                {neverLandGameData.gameRound !== -1 ? "프로토 승부식 " + neverLandGameData.gameRound + "회차" : "정보를 가져오는 중..."}
              </p>
          }

          <Clock/>
        </div>
      </div>
  )
}
export default Header;

