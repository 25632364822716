import {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {RiQuestionFill} from "react-icons/ri";

const SelectModal = ({messageTitle, messageContents, setIsSelectModalOpen, selectModalMenu}) => {
  const [open, setOpen] = useState(true);


  const closeModal = () => {
    setOpen(false);
    setIsSelectModalOpen(false);
  }


  function onItemClick(item) {
    try {
      let args = {
        isClose: true
      };
      item.onClick(args, item.text, item.params);
      if (args.isClose)
        closeModal();
    } catch {
    }
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex w-16 h-16 items-center justify-center rounded-full">
                      <RiQuestionFill className="h-16 w-16 text-blue-600"/>
                    </div>

                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                        {messageTitle}
                      </Dialog.Title>

                      <p className="text-lg text-gray-500 mt-2">
                        {messageContents}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex flex-row">

                    {selectModalMenu.items.map((item, index) => {
                      return <button
                          key="confirm"
                          type="button"
                          className="text-lg inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 font-semibold text-white shadow-sm mr-8"
                          onClick={() => onItemClick(item)}
                      >
                        {item.text}
                      </button>;
                    })
                    }

                    <button
                        key="cancle"
                        type="button"
                        className="text-lg inline-flex w-full justify-center rounded-md px-3 bg-gray-400  py-2 font-semibold text-white shadow-sm"
                        onClick={closeModal}
                    >
                      취소
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default SelectModal;