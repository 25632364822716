import {Switch} from '@headlessui/react'
import {RiMoonFill, RiSunFill} from "react-icons/ri";

const ColorModeToggle = ({lightMode, setLightMode}) => {

  return (
      <Switch
          checked={lightMode}
          onChange={setLightMode}
          className="group relative inline-flex h-9 w-20 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-yellow-300 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-slate-900 focus:ring-offset-2 data-[checked]:bg-slate-900"
      >
        <span
            className="pointer-events-none relative inline-block h-8 w-8 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-11">
        <span
            aria-hidden="true"
            className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
        >
          <RiSunFill className="h-6 w-6 text-amber-500"/>
        </span>

        <span
            aria-hidden="true"
            className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
        >
          <RiMoonFill className="h-6 w-6 text-amber-500"/>
        </span>
      </span>
      </Switch>
  )
}
export default ColorModeToggle;