import {
  CUSTOM_PICK_PAGE,
  GAME_ANALYSIS_PAGE,
  HAPPY_PICK_PAGE,
  PROTO_GAME_PAGE, TOTO_PAGE
} from "./page_constants";
import {RiBookmarkLine, RiEmotionHappyLine, RiPieChartLine, RiPuzzleLine} from "react-icons/ri";

// <-- 헤더 --> //
export const HEADER_MENU = [
  {name: "프로토 경기", link: PROTO_GAME_PAGE, icon: RiBookmarkLine},
  {name: "토토", link: TOTO_PAGE, icon: RiBookmarkLine},
  // {name: "경기 분석", link: GAME_ANALYSIS_PAGE, icon: RiPieChartLine},
  // {name: "행복픽", link: HAPPY_PICK_PAGE, icon: RiEmotionHappyLine},
  // {name: "커스텀픽", link: CUSTOM_PICK_PAGE, icon: RiPuzzleLine},
]

// <-- 메인페이지 경기 필터 버튼 --> //
export const PROTO_CATEGORY_FILTER_BUTTONS_DATA = [
  {name: "전체", value: "all"},
  {name: "축구", value: "SC"},
  {name: "농구", value: "BK"},
  {name: "야구", value: "BS"},
  {name: "배구", value: "VL"},
]

// <-- 토토 경기 필터 버튼 --> //
export const TOTO_CATEGORY_FILTER_BUTTONS_DATA = [
  {name: "축구토토", value: "sc"},
  {name: "농구토토", value: "bk"},
  {name: "야구토토", value: "bs"},
  {name: "배구토토", value: "vl"},
  {name: "골프토토", value: "gf"},
  {name: "언더오버", value: "uo"},
]

// <-- 메인페이지 필터조건 테이블 --> 전자게시판에서 사용 XXX //
export const FILTER_TABLE_DATA = [
  {
    title: '종목',
    options: [
      {id: 'category_all', value: '전체'},
      {id: 'category_soccer', value: '축구'},
      {id: 'category_basketball', value: '농구'},
      {id: 'category_baseball', value: '야구'},
      {id: 'category_volleyball', value: '배구'}
    ]
  }
];

// <-- 티켓 금액 버튼 --> //
export const MONEY_BUTTON_DATA = [
  {name: "+ 1백", value: 100,},
  {name: "+ 1천", value: 1000,},
  {name: "+ 5천", value: 5000,},
  {name: "+ 1만", value: 10000,},
  {name: "+ 2만", value: 20000,},
  {name: "+ 3만", value: 30000,},
  {name: "+ 5만", value: 50000,},
  {name: "+ 10만", value: 100000,},
]