import React from 'react';

// <-- 티켓카드 금액버튼 핸들링 -->
export const useTicketList = (ticketData, initialState) => {
  const [purchaseAmount, setPurchaseAmount] = React.useState(initialState);

  React.useEffect(() => {
    // 처음 렌더링될 때 데이터에 있는 개별투표금액 객체 담기
    const initialPurchaseAmount = {};
    ticketData.forEach((data) => {
      let currentAmount = Number(data.amount) || 0;
      if (data.newAmount) {
        currentAmount = data.newAmount;
      } else {
        data.newAmount = currentAmount;
      }
      initialPurchaseAmount[data.id] = currentAmount;
    })
    setPurchaseAmount(initialPurchaseAmount);
  }, [ticketData])
  const handleInputChange = (e, cardId, newvalue) => {
    let value;
    if (newvalue) {
      value = newvalue;
    } else {
      value = e.target.value;
    }
    value = value.replace(/\D/g, '');
    value = Math.floor(Number(value));

    // 인풋에 직접 입력했을 때 개별투표금액
    setPurchaseAmount((prevAmount) => ({
      ...prevAmount, [cardId]: value
    }));
  }
  const handleButtonClick = (cardId, value) => {
    // 금액 버튼 눌렀을때 개별투표금액
    setPurchaseAmount((prevAmount) => ({
      ...prevAmount, [cardId]: Number((purchaseAmount[cardId] || 0) + value)
    }));
  }
  const resetAmount = (cardId) => {
    // 금액 0으로 초기화
    setPurchaseAmount((prevAmount) => ({
      ...prevAmount, [cardId]: 0
    }));
  }
  //purchaseAmount 클래스로 전달
  return [purchaseAmount, handleInputChange, handleButtonClick, resetAmount];
}