import {RiArrowDownDoubleLine, RiArrowUpDoubleLine} from "react-icons/ri";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {isLandscapeMode} from "../utils/componentUtils";

const ScrollButton = ({gameCardRef}) => {
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false); // 스크롤 가능 여부

  const handleScroll = () => {
    const scrollTop = gameCardRef.current.scrollTop;
    const scrollHeight = gameCardRef.current.scrollHeight;
    const clientHeight = gameCardRef.current.clientHeight;

    let _isTop = scrollTop === 0;
    let _isBottom = scrollTop + clientHeight === scrollHeight;
    setIsTop(_isTop);
    setIsBottom(_isBottom);

    setIsScrollable(scrollHeight > clientHeight);
  };


  // // 페이지 이동 할 때마다 스크롤 최상위 위치
  // const {pathname} = useLocation();
  // useEffect(() => {
  //   const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  //   if (scrollTop !== 0)
  //     window.scrollTo(0, 0);
  //   else handleScroll();
  // }, [pathname]);


  useEffect(() => {
    handleScroll();

    const currentElement = gameCardRef?.current;

    if (currentElement) {
      currentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentElement) {
        currentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    gameCardRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToBottom = () => {
    gameCardRef.current.scrollTo({
      top: gameCardRef.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  // 스크롤이 없으면 버튼을 렌더링하지 않음
  if (!isScrollable) {
    return null;
  }

  return (
      <div className='flex flex-col ri-align-center'>
        {!isTop && (
            <button
                onClick={scrollToTop}
                className={`${isLandscapeMode() ? "left-[36.5rem]" : "right-[25.5rem]"} mt-40 fixed top-0 z-30 bg-opacity-70 bg-gray-600 h-auto w-auto text-white rounded-full px-1 py-1.5`}>
              <RiArrowUpDoubleLine className='h-10 w-auto'/>
            </button>
        )}

        {!isBottom && (
            <button
                onClick={scrollToBottom}
                className={`${isLandscapeMode() ? "mb-8 left-[36.5rem]" : "mb-8 right-[25.5rem]"} fixed bottom-0 z-30 bg-opacity-70 bg-gray-600 h-auto w-auto text-white rounded-full px-1 py-1.5`}>
              <RiArrowDownDoubleLine className='h-10 w-auto'/>
            </button>
        )}
      </div>
  )
}
export default ScrollButton