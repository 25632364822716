import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {CheckIcon} from "@heroicons/react/20/solid";
import {ERROR_PAGE, SETTING_PAGE} from "../../constants/page_constants";
import {useLocation, useNavigate} from "react-router-dom";
import {RiCloseLine, RiLogoutBoxLine, RiLogoutBoxRLine, RiQuestionFill, RiSettings4Fill} from "react-icons/ri";
import {forcedDeviceLogout} from "../../utils/WindowInterfaces";
import neverLandUtils from "../../utils/NeverLandUtils";

const LogoutModal = ({setIsModalOpen}) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const closeModal = () => {
    setOpen(false);
    setIsModalOpen(false);
  }

  const confirm = () => {
    try {
      forcedDeviceLogout('#정상종료');
      neverLandUtils.commons.clear();
      closeModal();
    } catch {

    }
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  {/*<-- 설정 페이지 이동 버튼 -->*/}
                  <div className="flex justify-end">
                    <button onClick={() => navigate(SETTING_PAGE)}>
                      <RiSettings4Fill className="w-8 h-8 text-gray-500"/>
                    </button>
                  </div>

                  <div>
                    <div className="mx-auto flex w-16 h-16 items-center justify-center rounded-full bg-amber-100">
                      <RiLogoutBoxRLine className="h-8 w-8 text-amber-500"/>
                    </div>

                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                        프로그램 종료
                      </Dialog.Title>

                      <div className="mt-2">
                        <p className="text-lg text-gray-500">
                          프로그램을 종료 하시겠습니까?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex flex-row">
                    <button
                        type="button"
                        className="text-lg inline-flex w-full justify-center rounded-md bg-gray-400 px-3 py-2 font-semibold text-white shadow-sm mr-8"
                        onClick={confirm}
                    >
                      확인
                    </button>

                    <button
                        type="button"
                        className="text-lg inline-flex w-full justify-center rounded-md bg-amber-500 px-3 py-2 font-semibold text-white shadow-sm"
                        onClick={closeModal}
                    >
                      취소
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default LogoutModal;