import {useEffect, useState} from "react";

export const useLocalStorage = (key) => {
  // 로컬 스토리지 변경을 감지하는 커스텀 훅
  const [data, setData] = useState(() => {
    let item = localStorage.getItem(key);
    return item === null ? "" : item;
  });

  useEffect(() => {
    //로컬 스토리지가 변경되면 storage 변경 이벤트 리스너 등록
    const handleStorageChange = () => {
      let item = localStorage.getItem(key);
      setData(item === null ? "" : item);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return [data, setData];
};