import axios from "axios";

const {REACT_APP_API_GAME_ANALYSiS_URL} = process.env;

function _getBaseURL(url) {
  let currentPath = window.location.href;

  if (currentPath.includes("https://") && url.includes("http://")) {
    url = "https://" + new URL(currentPath).hostname + "/api4/";
  } else {
  }
  return url;
}


const fetcher = axios.create({
  baseURL: _getBaseURL(REACT_APP_API_GAME_ANALYSiS_URL)
});

export function getFetcherAnalysis() {
  return fetcher;
}

