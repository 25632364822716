import {useParams} from "react-router-dom";

const ErrorPage = () => {
  const {id} = useParams();

  return (
      <>
        <p className="text-2xl text-red-600 text-center"> 접근하실 수 없습니다. </p>
        {id && <p className="text-2xl text-red-600 text-center"> ID: {id} </p>}
      </>
  )
}
export default ErrorPage;