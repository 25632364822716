export const _gameInfo = {
  "result": {
    "tag": {
      "informationTime": "kr-0-1725858014166x0",
      "gameRound": "110",
      "filters": {
        "leagueName": [
          "C네이션",
          "KBO",
          "MLB",
          "NPB",
          "U네이션",
          "남축INTL",
          "축월드예"
        ],
        "closingDate": [
          "2024-09-09",
          "2024-09-10",
          "2024-09-11"
        ]
      },
      "isUpdated": true
    },
    "gameInfo": [
      {
        "dataId": "bd_G101_240110_0005",
        "closingDate": "2024-09-12 10:15:00",
        "gameplayDate": "2024-09-10 01:00:00",
        "leagueName": "U네이션",
        "state": "발매중",
        "homeTeam": "키프로스",
        "awayTeam": "코소보",
        "category": "SC",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0005_0026",
            "gameNo": "26ⓢ",
            "win": "3.85",
            "draw": "3.10",
            "extra": "0.0",
            "lose": "1.76",
            "extraText": "무",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0005_0027",
            "gameNo": "27ⓢ",
            "win": "1.83",
            "draw": "3.25",
            "extra": "1.0",
            "lose": "3.40",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0005_0028",
            "gameNo": "28ⓢ",
            "win": "1.52",
            "draw": "0.00",
            "extra": "2.5",
            "lose": "2.09",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0005_0029",
            "gameNo": "29ⓢ",
            "win": "1.78",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "1.74",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0024",
        "closingDate": "2024-09-12 10:20:00",
        "gameplayDate": "2024-09-10 11:10:00",
        "leagueName": "MLB",
        "state": "발매중",
        "homeTeam": "LA다저스",
        "awayTeam": "시카컵스",
        "category": "BS",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0024_0118",
            "gameNo": "118ⓢ",
            "win": "1.42",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.31",
            "extraText": "null",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0024_0119",
            "gameNo": "119ⓢ",
            "win": "1.83",
            "draw": "3.65",
            "extra": "0.0",
            "lose": "3.05",
            "extraText": "무",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0024_0120",
            "gameNo": "120ⓢ",
            "win": "2.29",
            "draw": "0.00",
            "extra": "-2.5",
            "lose": "1.43",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0024_0121",
            "gameNo": "121ⓢ",
            "win": "1.82",
            "draw": "0.00",
            "extra": "9.5",
            "lose": "1.70",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0024_0122",
            "gameNo": "122ⓢ",
            "win": "1.51",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.11",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0025",
        "closingDate": "2024-09-14 12:00:00",
        "gameplayDate": "2024-09-10 18:00:00",
        "leagueName": "NPB",
        "state": "발매중",
        "homeTeam": "주니치",
        "awayTeam": "야쿠르트",
        "category": "BS",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0025_0123",
            "gameNo": "123ⓢ",
            "win": "1.52",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.09",
            "extraText": "null",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0025_0124",
            "gameNo": "124ⓢ",
            "win": "2.45",
            "draw": "2.45",
            "extra": "0.0",
            "lose": "3.00",
            "extraText": "①",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0025_0125",
            "gameNo": "125ⓢ",
            "win": "3.55",
            "draw": "0.00",
            "extra": "-2.5",
            "lose": "1.17",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0025_0126",
            "gameNo": "126ⓢ",
            "win": "1.86",
            "draw": "0.00",
            "extra": "4.5",
            "lose": "1.67",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0025_0127",
            "gameNo": "127ⓢ",
            "win": "1.47",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.19",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0026",
        "closingDate": "2024-09-14 13:10:00",
        "gameplayDate": "2024-09-10 18:00:00",
        "leagueName": "NPB",
        "state": "발매중",
        "homeTeam": "한신",
        "awayTeam": "요코베이",
        "category": "BS",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0026_0128",
            "gameNo": "128ⓢ",
            "win": "1.87",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "1.66",
            "extraText": "null",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0026_0129",
            "gameNo": "129ⓢ",
            "win": "3.15",
            "draw": "2.65",
            "extra": "0.0",
            "lose": "2.20",
            "extraText": "①",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0026_0130",
            "gameNo": "130ⓢ",
            "win": "1.22",
            "draw": "0.00",
            "extra": "2.5",
            "lose": "3.16",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0026_0131",
            "gameNo": "131ⓢ",
            "win": "1.64",
            "draw": "0.00",
            "extra": "6.5",
            "lose": "1.90",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0026_0132",
            "gameNo": "132ⓢ",
            "win": "1.49",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.15",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0027",
        "closingDate": "2024-09-14 18:00:00",
        "gameplayDate": "2024-09-10 18:00:00",
        "leagueName": "NPB",
        "state": "발매중",
        "homeTeam": "히로카프",
        "awayTeam": "요미우리",
        "category": "BS",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0027_0133",
            "gameNo": "133",
            "win": "1.82",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "1.70",
            "extraText": "null",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0027_0134",
            "gameNo": "134",
            "win": "3.10",
            "draw": "2.50",
            "extra": "0.0",
            "lose": "2.34",
            "extraText": "①",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0027_0135",
            "gameNo": "135",
            "win": "1.19",
            "draw": "0.00",
            "extra": "2.5",
            "lose": "3.38",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0027_0136",
            "gameNo": "136",
            "win": "1.65",
            "draw": "0.00",
            "extra": "5.5",
            "lose": "1.89",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0027_0137",
            "gameNo": "137",
            "win": "1.48",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.17",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0028",
        "closingDate": "2024-09-14 18:00:00",
        "gameplayDate": "2024-09-10 18:00:00",
        "leagueName": "NPB",
        "state": "발매중",
        "homeTeam": "닛폰햄",
        "awayTeam": "세이부",
        "category": "BS",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0028_0138",
            "gameNo": "138",
            "win": "1.34",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.56",
            "extraText": "null",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0028_0139",
            "gameNo": "139",
            "win": "1.97",
            "draw": "2.75",
            "extra": "0.0",
            "lose": "3.60",
            "extraText": "①",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0028_0140",
            "gameNo": "140",
            "win": "2.72",
            "draw": "0.00",
            "extra": "-2.5",
            "lose": "1.30",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0028_0141",
            "gameNo": "141",
            "win": "1.81",
            "draw": "0.00",
            "extra": "5.5",
            "lose": "1.71",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0028_0142",
            "gameNo": "142",
            "win": "1.50",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "2.13",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0006",
        "closingDate": "2024-09-14 22:00:00",
        "gameplayDate": "2024-09-10 03:45:00",
        "leagueName": "U네이션",
        "state": "발매중",
        "homeTeam": "이스라엘",
        "awayTeam": "이탈리아",
        "category": "SC",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0006_0030",
            "gameNo": "30ⓢ",
            "win": "8.70",
            "draw": "4.80",
            "extra": "0.0",
            "lose": "1.21",
            "extraText": "무",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0006_0031",
            "gameNo": "31ⓢ",
            "win": "3.40",
            "draw": "3.50",
            "extra": "1.0",
            "lose": "1.76",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0006_0032",
            "gameNo": "32ⓢ",
            "win": "1.82",
            "draw": "3.75",
            "extra": "2.0",
            "lose": "3.00",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0006_0033",
            "gameNo": "33ⓢ",
            "win": "1.05",
            "draw": "0.00",
            "extra": "3.5",
            "lose": "5.44",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0006_0034",
            "gameNo": "34ⓢ",
            "win": "2.15",
            "draw": "0.00",
            "extra": "2.5",
            "lose": "1.49",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0006_0035",
            "gameNo": "35ⓢ",
            "win": "1.76",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "1.76",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0007",
        "closingDate": "2024-09-14 22:00:00",
        "gameplayDate": "2024-09-10 03:45:00",
        "leagueName": "U네이션",
        "state": "발매중",
        "homeTeam": "튀르키예",
        "awayTeam": "아이슬란",
        "category": "SC",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0007_0036",
            "gameNo": "36ⓢ",
            "win": "1.48",
            "draw": "3.75",
            "extra": "0.0",
            "lose": "4.85",
            "extraText": "무",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0007_0037",
            "gameNo": "37ⓢ",
            "win": "2.45",
            "draw": "3.40",
            "extra": "-1.0",
            "lose": "2.24",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0007_0038",
            "gameNo": "38ⓢ",
            "win": "4.95",
            "draw": "4.40",
            "extra": "-2.0",
            "lose": "1.39",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0007_0039",
            "gameNo": "39ⓢ",
            "win": "6.84",
            "draw": "0.00",
            "extra": "-3.5",
            "lose": "1.01",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0007_0040",
            "gameNo": "40ⓢ",
            "win": "1.96",
            "draw": "0.00",
            "extra": "2.5",
            "lose": "1.60",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0007_0041",
            "gameNo": "41ⓢ",
            "win": "1.77",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "1.75",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0008",
        "closingDate": "2024-09-14 22:00:00",
        "gameplayDate": "2024-09-10 03:45:00",
        "leagueName": "U네이션",
        "state": "발매중",
        "homeTeam": "프랑스",
        "awayTeam": "벨기에",
        "category": "SC",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0008_0042",
            "gameNo": "42ⓢ",
            "win": "1.70",
            "draw": "3.40",
            "extra": "0.0",
            "lose": "3.75",
            "extraText": "무",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0008_0043",
            "gameNo": "43ⓢ",
            "win": "3.05",
            "draw": "3.40",
            "extra": "-1.0",
            "lose": "1.90",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0008_0044",
            "gameNo": "44ⓢ",
            "win": "1.81",
            "draw": "0.00",
            "extra": "2.5",
            "lose": "1.71",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0008_0045",
            "gameNo": "45ⓢ",
            "win": "1.77",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "1.75",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      },
      {
        "dataId": "bd_G101_240110_0009",
        "closingDate": "2024-09-11 22:00:00",
        "gameplayDate": "2024-09-10 03:45:00",
        "leagueName": "U네이션",
        "state": "발매중",
        "homeTeam": "몬테네그",
        "awayTeam": "웨일스",
        "category": "SC",
        "resultScript": null,
        "data": [
          {
            "allotId": "bd_G101_240110_0009_0046",
            "gameNo": "46ⓢ",
            "win": "2.80",
            "draw": "3.05",
            "extra": "0.0",
            "lose": "2.15",
            "extraText": "무",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0009_0047",
            "gameNo": "47ⓢ",
            "win": "1.52",
            "draw": "3.60",
            "extra": "1.0",
            "lose": "4.70",
            "extraText": "H",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0009_0048",
            "gameNo": "48ⓢ",
            "win": "1.54",
            "draw": "0.00",
            "extra": "2.5",
            "lose": "2.05",
            "extraText": "U/O",
            "lockState": "0",
            "lockStateByAdmin": "0"
          },
          {
            "allotId": "bd_G101_240110_0009_0049",
            "gameNo": "49ⓢ",
            "win": "1.77",
            "draw": "0.00",
            "extra": "0.0",
            "lose": "1.75",
            "extraText": "SUM",
            "lockState": "0",
            "lockStateByAdmin": "0"
          }
        ]
      }]
  }
}

export const _ticketCard = [
  {
    "id": 112321,
    "number": "154596",
    "requestNumber": "9",
    "progressDate": "-",
    "extraDescription": null,
    "extraTypeDescription": null,
    "closingDate": "2024-09-02 22:00:00",
    "gameplayDate": "2024-09-03 03:10:00",
    "state": "order<-expired",
    "gameDataResult": "lose",
    "amount": 10000,
    "unitType": "won",
    "registDate": "2024-09-02 15:26:23",
    "totalRate": 11.3,
    "totalRateNew": 0.0,
    "registerName": "은서짱 | 연락처 미등록",
    "registerMemo": "",
    "reservDate": "2024. 09. 02. 15:25",
    "chargerName": "큐티섹시 | -",
    "chargerMemo": "-",
    "gameRound": "G101_24107",
    "selectGame": [
      {
        "allotId": "bd_G101_240107_0007_0032",
        "gameNumber": "H 032",
        "homeTeam": "밀워브루 - 1.5",
        "awayTeam": "세인카디",
        "prediction": "승",
        "rate": 2.0,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "H -1.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0011_0045",
        "gameNumber": "U 045",
        "homeTeam": "애리다이 8.5",
        "awayTeam": "LA다저스",
        "prediction": "승",
        "rate": 1.79,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "U/O 8.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0027_0104",
        "gameNumber": "⒮*104",
        "homeTeam": "NC",
        "awayTeam": "키움",
        "prediction": "패",
        "rate": 2.02,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0028_0109",
        "gameNumber": "⒮*109",
        "homeTeam": "KIA",
        "awayTeam": "LG",
        "prediction": "승",
        "rate": 1.56,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      }
    ]
  },
  {
    "id": 2342345,
    "number": "154595",
    "requestNumber": "8",
    "progressDate": "-",
    "extraDescription": null,
    "extraTypeDescription": null,
    "closingDate": "2024-09-02 18:00:00",
    "gameplayDate": "2024-09-02 18:00:00",
    "state": "order<-expired",
    "gameDataResult": "lose",
    "amount": 10000,
    "unitType": "won",
    "registDate": "2024-09-02 15:26:23",
    "totalRate": 6.9,
    "totalRateNew": 0.0,
    "registerName": "은서짱 | 연락처 미등록",
    "registerMemo": "",
    "reservDate": "2024. 09. 02. 15:25",
    "chargerName": "큐티섹시 | -",
    "chargerMemo": "-",
    "gameRound": "G101_24107",
    "selectGame": [
      {
        "allotId": "bd_G101_240107_0001_0006",
        "gameNumber": "H*006",
        "homeTeam": "라쿠텐 + 2.5",
        "awayTeam": "오릭스",
        "prediction": "승",
        "rate": 1.21,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "H 2.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0011_0045",
        "gameNumber": "U 045",
        "homeTeam": "애리다이 8.5",
        "awayTeam": "LA다저스",
        "prediction": "승",
        "rate": 1.79,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "U/O 8.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0027_0104",
        "gameNumber": "⒮*104",
        "homeTeam": "NC",
        "awayTeam": "키움",
        "prediction": "패",
        "rate": 2.02,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0028_0109",
        "gameNumber": "⒮*109",
        "homeTeam": "KIA",
        "awayTeam": "LG",
        "prediction": "승",
        "rate": 1.56,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      }
    ]
  }, {
    "id": 154534,
    "number": "154595",
    "requestNumber": "8",
    "progressDate": "-",
    "extraDescription": null,
    "extraTypeDescription": null,
    "closingDate": "2024-09-02 18:00:00",
    "gameplayDate": "2024-09-02 18:00:00",
    "state": "order<-expired",
    "gameDataResult": "lose",
    "amount": 10000,
    "unitType": "won",
    "registDate": "2024-09-02 15:26:23",
    "totalRate": 6.9,
    "totalRateNew": 0.0,
    "registerName": "은서짱 | 연락처 미등록",
    "registerMemo": "",
    "reservDate": "2024. 09. 02. 15:25",
    "chargerName": "큐티섹시 | -",
    "chargerMemo": "-",
    "gameRound": "G101_24107",
    "selectGame": [
      {
        "allotId": "bd_G101_240107_0001_0006",
        "gameNumber": "H*006",
        "homeTeam": "라쿠텐 + 2.5",
        "awayTeam": "오릭스",
        "prediction": "승",
        "rate": 1.21,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "H 2.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0011_0045",
        "gameNumber": "U 045",
        "homeTeam": "애리다이 8.5",
        "awayTeam": "LA다저스",
        "prediction": "승",
        "rate": 1.79,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "U/O 8.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0027_0104",
        "gameNumber": "⒮*104",
        "homeTeam": "NC",
        "awayTeam": "키움",
        "prediction": "패",
        "rate": 2.02,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0028_0109",
        "gameNumber": "⒮*109",
        "homeTeam": "KIA",
        "awayTeam": "LG",
        "prediction": "승",
        "rate": 1.56,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      }
    ]
  }, {
    "id": 2342345,
    "number": "154595",
    "requestNumber": "8",
    "progressDate": "-",
    "extraDescription": null,
    "extraTypeDescription": null,
    "closingDate": "2024-09-02 18:00:00",
    "gameplayDate": "2024-09-02 18:00:00",
    "state": "order<-expired",
    "gameDataResult": "lose",
    "amount": 10000,
    "unitType": "won",
    "registDate": "2024-09-02 15:26:23",
    "totalRate": 6.9,
    "totalRateNew": 0.0,
    "registerName": "은서짱 | 연락처 미등록",
    "registerMemo": "",
    "reservDate": "2024. 09. 02. 15:25",
    "chargerName": "큐티섹시 | -",
    "chargerMemo": "-",
    "gameRound": "G101_24107",
    "selectGame": [
      {
        "allotId": "bd_G101_240107_0001_0006",
        "gameNumber": "H*006",
        "homeTeam": "라쿠텐 + 2.5",
        "awayTeam": "오릭스",
        "prediction": "승",
        "rate": 1.21,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "H 2.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0011_0045",
        "gameNumber": "U 045",
        "homeTeam": "애리다이 8.5",
        "awayTeam": "LA다저스",
        "prediction": "승",
        "rate": 1.79,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "U/O 8.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0027_0104",
        "gameNumber": "⒮*104",
        "homeTeam": "NC",
        "awayTeam": "키움",
        "prediction": "패",
        "rate": 2.02,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0028_0109",
        "gameNumber": "⒮*109",
        "homeTeam": "KIA",
        "awayTeam": "LG",
        "prediction": "승",
        "rate": 1.56,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      }
    ]
  }, {
    "id": 2342345,
    "number": "154595",
    "requestNumber": "8",
    "progressDate": "-",
    "extraDescription": null,
    "extraTypeDescription": null,
    "closingDate": "2024-09-02 18:00:00",
    "gameplayDate": "2024-09-02 18:00:00",
    "state": "order<-expired",
    "gameDataResult": "lose",
    "amount": 10000,
    "unitType": "won",
    "registDate": "2024-09-02 15:26:23",
    "totalRate": 6.9,
    "totalRateNew": 0.0,
    "registerName": "은서짱 | 연락처 미등록",
    "registerMemo": "",
    "reservDate": "2024. 09. 02. 15:25",
    "chargerName": "큐티섹시 | -",
    "chargerMemo": "-",
    "gameRound": "G101_24107",
    "selectGame": [
      {
        "allotId": "bd_G101_240107_0001_0006",
        "gameNumber": "H*006",
        "homeTeam": "라쿠텐 + 2.5",
        "awayTeam": "오릭스",
        "prediction": "승",
        "rate": 1.21,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "H 2.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0011_0045",
        "gameNumber": "U 045",
        "homeTeam": "애리다이 8.5",
        "awayTeam": "LA다저스",
        "prediction": "승",
        "rate": 1.79,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "U/O 8.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0027_0104",
        "gameNumber": "⒮*104",
        "homeTeam": "NC",
        "awayTeam": "키움",
        "prediction": "패",
        "rate": 2.02,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0028_0109",
        "gameNumber": "⒮*109",
        "homeTeam": "KIA",
        "awayTeam": "LG",
        "prediction": "승",
        "rate": 1.56,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      }
    ]
  }, {
    "id": 2342345,
    "number": "154595",
    "requestNumber": "8",
    "progressDate": "-",
    "extraDescription": null,
    "extraTypeDescription": null,
    "closingDate": "2024-09-02 18:00:00",
    "gameplayDate": "2024-09-02 18:00:00",
    "state": "order<-expired",
    "gameDataResult": "lose",
    "amount": 10000,
    "unitType": "won",
    "registDate": "2024-09-02 15:26:23",
    "totalRate": 6.9,
    "totalRateNew": 0.0,
    "registerName": "은서짱 | 연락처 미등록",
    "registerMemo": "",
    "reservDate": "2024. 09. 02. 15:25",
    "chargerName": "큐티섹시 | -",
    "chargerMemo": "-",
    "gameRound": "G101_24107",
    "selectGame": [
      {
        "allotId": "bd_G101_240107_0001_0006",
        "gameNumber": "H*006",
        "homeTeam": "라쿠텐 + 2.5",
        "awayTeam": "오릭스",
        "prediction": "승",
        "rate": 1.21,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "H 2.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0011_0045",
        "gameNumber": "U 045",
        "homeTeam": "애리다이 8.5",
        "awayTeam": "LA다저스",
        "prediction": "승",
        "rate": 1.79,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "U/O 8.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0027_0104",
        "gameNumber": "⒮*104",
        "homeTeam": "NC",
        "awayTeam": "키움",
        "prediction": "패",
        "rate": 2.02,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0028_0109",
        "gameNumber": "⒮*109",
        "homeTeam": "KIA",
        "awayTeam": "LG",
        "prediction": "승",
        "rate": 1.56,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      }
    ]
  }, {
    "id": 2342345,
    "number": "154595",
    "requestNumber": "8",
    "progressDate": "-",
    "extraDescription": null,
    "extraTypeDescription": null,
    "closingDate": "2024-09-02 18:00:00",
    "gameplayDate": "2024-09-02 18:00:00",
    "state": "order<-expired",
    "gameDataResult": "lose",
    "amount": 10000,
    "unitType": "won",
    "registDate": "2024-09-02 15:26:23",
    "totalRate": 6.9,
    "totalRateNew": 0.0,
    "registerName": "은서짱 | 연락처 미등록",
    "registerMemo": "",
    "reservDate": "2024. 09. 02. 15:25",
    "chargerName": "큐티섹시 | -",
    "chargerMemo": "-",
    "gameRound": "G101_24107",
    "selectGame": [
      {
        "allotId": "bd_G101_240107_0001_0006",
        "gameNumber": "H*006",
        "homeTeam": "라쿠텐 + 2.5",
        "awayTeam": "오릭스",
        "prediction": "승",
        "rate": 1.21,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "H 2.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0011_0045",
        "gameNumber": "U 045",
        "homeTeam": "애리다이 8.5",
        "awayTeam": "LA다저스",
        "prediction": "승",
        "rate": 1.79,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "U/O 8.5",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0027_0104",
        "gameNumber": "⒮*104",
        "homeTeam": "NC",
        "awayTeam": "키움",
        "prediction": "패",
        "rate": 2.02,
        "allotResult": "o",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      },
      {
        "allotId": "bd_G101_240107_0028_0109",
        "gameNumber": "⒮*109",
        "homeTeam": "KIA",
        "awayTeam": "LG",
        "prediction": "승",
        "rate": 1.56,
        "allotResult": "x",
        "rateNew": -1.0,
        "extraText": "SUM 0.0",
        "extraTextNew": null,
        "isChanged": false
      }
    ]
  },
]

export const _rateData = [
  {name: "승무패", rateWin: "1.83", rateDraw: "-", rateLose: "3.05",},
  {name: "승일패", rateWin: "1.94", rateDraw: "3.45", rateLose: "2.90",},
  {name: "핸디 -2.5", rateWin: "2.29", rateDraw: "-", rateLose: "1.43",},
  {name: "언더오버 (9.5)", rateWin: "1.82", rateDraw: "-", rateLose: "1.70",},
  {name: "SUM", rateWin: "1.51", rateDraw: "-", rateLose: "2.11",},
]

export const _sameGameRateData = [
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'w',
    resultText: '핸디승',
    extraText: '핸디',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'l',
    resultText: '언더',
    extraText: '언/오버',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },{
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '오버',
    extraText: '언/오버',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '오버',
    extraText: '언/오버',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'l',
    resultText: '핸디패',
    extraText: '핸디',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'w',
    resultText: '핸디승',
    extraText: '핸디',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'l',
    resultText: '언더',
    extraText: '언/오버',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '오버',
    extraText: '언/오버',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '오버',
    extraText: '언/오버',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'l',
    resultText: '핸디패',
    extraText: '핸디',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'l',
    resultText: '핸디패',
    extraText: '핸디',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'w',
    resultText: '핸디승',
    extraText: '핸디',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'l',
    resultText: '언더',
    extraText: '언/오버',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
  {
    date: '2024-01-01',
    homeTeam: '튀르키예',
    awayTeam: '아이슬란',
    result: 'd',
    resultText: '홈승',
    extraText: '승무패',
    win: "1.48",
    draw: "3.75",
    lose: "4.85"
  },
]

export const _happyPickData = [
  {
    gameRound: 5,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '경기전'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '경기전'
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '홀',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '경기전'
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '오버',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '경기전'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '핸디',
        rateText: '무승부',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '경기전'
      },]
  }, {
    gameRound: 76,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '언/오버',
      rateText: '언더',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '경기중'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '언더'
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '경기중'
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '오버'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '경기전'
      },]
  }, {
    gameRound: 123,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디승'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디패'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '홈승'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '무승부'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '짝'
      },]
  }, , {
    gameRound: 123,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디승'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디패'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '홈승'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '무승부'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '짝'
      },]
  }, , {
    gameRound: 123,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디승'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디패'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '홈승'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '무승부'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '짝'
      },]
  }, , {
    gameRound: 123,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디승'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디패'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '홈승'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '무승부'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '짝'
      },]
  }, , {
    gameRound: 123,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디승'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디패'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '홈승'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '무승부'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '짝'
      },]
  }, , {
    gameRound: 123,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디승'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디패'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '홈승'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '무승부'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '짝'
      },]
  }, , {
    gameRound: 123,
    predictionRate: '65.01 ~ 145.65',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디승'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '핸디패'
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85",
      state: '홈승'
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '무승부'
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85",
        state: '짝'
      },]
  },
]

export const _customPickData = [
  {
    league: 'NPB',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '경기전',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '홀',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '오버',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '핸디',
        rateText: '무승부',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: 'KBO',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '언/오버',
      rateText: '언더',
      rate: '1.98',
      state: '경기중',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기중',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '오버',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: '남축INTL',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      rate: '1.98',
      state: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      rate: '1.98',
      state: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        rate: '1.98',
        state: '무승부',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        rate: '1.98',
        state: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: '세리에A',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      rate: '1.98',
      state: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      rate: '1.98',
      state: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        rate: '1.98',
        state: '무승부',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        rate: '1.98',
        state: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: 'NPB',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '경기전',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '홀',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '오버',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '핸디',
        rateText: '무승부',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: 'KBO',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '언/오버',
      rateText: '언더',
      rate: '1.98',
      state: '경기중',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기중',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '오버',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: '남축INTL',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      rate: '1.98',
      state: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      rate: '1.98',
      state: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        rate: '1.98',
        state: '무승부',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        rate: '1.98',
        state: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: '세리에A',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      rate: '1.98',
      state: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      rate: '1.98',
      state: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        rate: '1.98',
        state: '무승부',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        rate: '1.98',
        state: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: 'NPB',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '경기전',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '홀',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '오버',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '핸디',
        rateText: '무승부',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: 'KBO',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '언/오버',
      rateText: '언더',
      rate: '1.98',
      state: '경기중',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '언더',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기중',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      }, {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '오버',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '언/오버',
        rateText: '언더',
        rate: '1.98',
        state: '경기전',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: '남축INTL',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      rate: '1.98',
      state: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      rate: '1.98',
      state: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        rate: '1.98',
        state: '무승부',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        rate: '1.98',
        state: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  }, {
    league: '세리에A',
    gameData: [{
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 4.0',
      rateText: '핸디승',
      rate: '1.98',
      state: '핸디승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '핸디 -1.0',
      rateText: '핸디패',
      rate: '1.98',
      state: '핸디패',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    }, {
      gameNumber: '123',
      date: '2024-01-01[08:45]',
      homeTeam: '튀르키예',
      awayTeam: '아이슬란',
      extraText: '승무패',
      rateText: '홈승',
      rate: '1.98',
      state: '홈승',
      win: "1.48",
      draw: "3.75",
      lose: "4.85"
    },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: '승무패',
        rateText: '홈패',
        rate: '1.98',
        state: '무승부',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },
      {
        gameNumber: '123',
        date: '2024-01-01[08:45]',
        homeTeam: '튀르키예',
        awayTeam: '아이슬란',
        extraText: 'SUM',
        rateText: '짝',
        rate: '1.98',
        state: '짝',
        win: "1.48",
        draw: "3.75",
        lose: "4.85"
      },]
  },
]

