import Clock from "../components/Clock";

const LoadingPage = () => {

  return (
      <div className="flex flex-col justify-center items-center">
        <p className="text-white mb-10 text-2xl">로딩중입니다.</p>
        <Clock/>
      </div>
  )
}
export default LoadingPage;