import {useEffect, useState} from "react";
import React from 'react';
import {RiLoopRightFill} from "react-icons/ri";
// import neverLandGameData from "../../utils/NeverLandGameData";
// import {FILTER_TABLE_DATA} from "../../constants/component_data";
// import neverLandUtils from "../../utils/NeverLandUtils";
// import {accountInfo} from "../../constants/constants";
import {isVisible} from "@testing-library/user-event/dist/utils";
import neverLandGameData from "../../utils/NeverLandGameData";
import {FILTER_TABLE_DATA} from "../../constants/component_constants";

const FilterCheckBoxTable = ({isFilterVisible}) => {  // FIXME : isFilterVisible <- ProtoAnalysisPage 에서 설정, useEffect 때문에 임시로 이 테이블 컴포넌트 넣어놓은거임
  // const [isShow, setIsShow] = useState( false
  //     // accountInfo.getUserConfig().isOn("on_show_filter_table", false)
  // );
  // const [checkOptions, setCheckOptions] = useState([]);
  const [redrawDate, setRedrawDate] = useState(new Date());
  const updateUI = () => {
    setRedrawDate(new Date());
  }
  //
  // const resetOptions = () => {
  //   // 기본 선택 옵션값 지정
  //   let initOptionIds = [];
  //
  //   initOptionIds.push("status_in_progress");
  //   initOptionIds.push("type_all");
  //   initOptionIds.push("category_all");
  //   initOptionIds.push("league_all");
  //   initOptionIds.push("closingDate_all");
  //
  //   setCheckOptions(initOptionIds);
  //   commitFilter(initOptionIds);
  // }

  useEffect(() => {
    // resetOptions();

    neverLandGameData.addOnUpdatedHandlers(handleOnChanged);
    neverLandGameData.beginAutoUpdate();  // 20초마다 게임 데이터 불러오기
    return () => {
      neverLandGameData.removeUpdatedHandlers(handleOnChanged);
      neverLandGameData.endAutoUpdate();  // 다른페이지로 이동하면 타이머 정지
    }
  }, []);

  const handleOnChanged = () => {
    // 데이터에서 옵션값 받아와서 리그, 마감일 옵션 저장하기
    // let filterOptions = neverLandGameData.filterOptions;
    //
    // if (filterOptions === null)
    //   return;
    //
    // let temp;
    // let index = 0;
    // for (let f of FILTER_TABLE_DATA) {
    //   if (f.title === "리그") {
    //     temp = [];
    //     temp.push(f.options[0]);
    //     index = 0;
    //     for (let l of filterOptions.leagueName) {
    //       temp.push({id: 'league_' + (index++), value: l});
    //     }
    //     f.options = temp;
    //   }
    //   if (f.title === "마감일") {
    //     temp = [];
    //     temp.push(f.options[0]);
    //     index = 0;
    //     for (let c of filterOptions.closingDate) {
    //       temp.push({id: 'closingDate_' + (index++), value: c});
    //     }
    //     f.options = temp;
    //   }
    // }
    updateUI();
  };
  //
  // function getFilterOptionsByID(id) {
  //   // 옵션 목록중에서 아이디에 해당하는 라인찾기
  //   let _options = null;
  //   FILTER_TABLE_DATA.map((data) => {
  //     if (_options === null) {
  //       if (data.options.find(opts => opts.id === id)) {
  //         _options = data;
  //         return _options;
  //       } else return null;
  //     }
  //   });
  //   return _options;
  // }
  //
  // function getFilterOptionsValueByID(id) {
  //   // 옵션 목록중에서 아이디에 해당하는 벨류찾기
  //   let _opts = null;
  //   FILTER_TABLE_DATA.map((data) => {
  //     if (_opts === null) {
  //       _opts = data.options.find(opts => opts.id === id);
  //       if (_opts) {
  //         return _opts.value;
  //       } else {
  //         _opts = null;
  //       }
  //     }
  //   });
  //   return _opts.value;
  // }
  //
  // function isAllOptionsActivated(currentOptions, newCheckOptions) {
  //   // 전체 제외하고 다 선택했는지 확인 -> 다선택하면 true : 전체 옵션 체크
  //   for (let item of currentOptions.options) {
  //     if (item.id.includes("_all")) continue;
  //     if (!newCheckOptions.includes(item.id)) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }
  //
  // const handleFilterCheck = (checked, id) => {
  //   // <-- 조회조건 체크박스 테이블 --> //
  //   let newCheckOptions = checkOptions;
  //   if (id.includes("_all")) {
  //     // <-- 전체옵션 체크 했을 때 --> //
  //     FILTER_TABLE_DATA.map((data) => {
  //       if (data.options.length === 0)
  //         return;
  //       if (data.options[0].id !== id)
  //         return;
  //       if (checked) {
  //         data.options.map((opt) => {
  //           if (newCheckOptions.includes(opt.id))
  //             return;
  //           newCheckOptions.push(opt.id);
  //         });
  //         newCheckOptions = [...newCheckOptions];
  //       } else {
  //         data.options.map((opt) => {
  //           newCheckOptions = newCheckOptions.filter((item) => item !== opt.id);
  //         });
  //       }
  //     });
  //     setCheckOptions(newCheckOptions);
  //     commitFilter(newCheckOptions);
  //     return;
  //   }
  //
  //   let current_options = getFilterOptionsByID(id);
  //
  //   // <-- 체크박스 하나씩 선택 했을 때 --> //
  //   if (checked) {
  //     // 체크한 아이템 배열에 추가
  //     newCheckOptions = [...newCheckOptions, id];
  //     if (current_options !== null && isAllOptionsActivated(current_options, newCheckOptions)) {
  //       let all_id = current_options.options[0].id;
  //       if (!newCheckOptions.includes(all_id)) {
  //         newCheckOptions = [...newCheckOptions, all_id];
  //       }
  //     } else if (current_options !== null) {
  //       let all_id = current_options.options[0].id;
  //       newCheckOptions = newCheckOptions.filter((item) => item !== all_id);
  //     }
  //   } else {
  //     // 선택 해제하면 배열에서 아이템 제외
  //     if (current_options !== null)
  //       newCheckOptions = newCheckOptions.filter((item) => item !== current_options.options[0].id);
  //     newCheckOptions = newCheckOptions.filter((item) => item !== id);
  //   }
  //   setCheckOptions(newCheckOptions);
  //   commitFilter(newCheckOptions);
  // }
  //
  // const commitFilter = (currentCheckOptions) => {
  //   // 테이블 체크여부 배열에 담아서 데이터 넘기기
  //   let index;
  //   let newFilteredData = {
  //     'status': [],
  //     'type': [],
  //     'category': [],
  //     'league': [],
  //     'closingDate': []
  //   };
  //
  //   for (let optionId of currentCheckOptions) {
  //     index = optionId.indexOf("_");
  //     if (index)
  //       newFilteredData[optionId.substring(0, index)].push(getFilterOptionsValueByID(optionId));
  //   }
  //   neverLandGameData.commitFilter(newFilteredData);
  // }
  //
  // function getValueText(value) {
  //   // 마감일 옵션값 날짜 형식 변경
  //   if (value.startsWith("20") && value.length === 10) {
  //     let _d = new Date(value);
  //     return (_d.getMonth() + 1) + "월 " + _d.getDate() + "일";
  //   }
  //   return value;
  // }

  if(!isFilterVisible) return (<></>);
  return (
      <>
        {/*<div className="mb-2 flex flex-row justify-start font-semibold text-gray-500">*/}
        {/*  <div className="items-center flex flex-row">*/}
        {/*    <p className="mr-2 text-sm text-gray-900 font-semibold"> 조회조건</p>*/}
        {/*    <div className="flex flex-row justify-center ring-2 ring-gray-900 rounded-lg">*/}
        {/*      <button*/}
        {/*          // onClick={() => setIsShow(accountInfo.getUserConfig().setOptionOn("on_show_filter_table", false))}*/}
        {/*          type="button"*/}
        {/*          className={`${isShow ? "bg-gray-100 text-gray-900" : "bg-gray-900 text-white"}, "whitespace-nowrap rounded-lg px-1.5 py-1 text-xs font-semibold shadow-sm"`}*/}
        {/*      >*/}
        {/*        접기*/}
        {/*      </button>*/}
        {/*      <button*/}
        {/*          // onClick={() => setIsShow(accountInfo.getUserConfig().setOptionOn("on_show_filter_table", true))}*/}
        {/*          type="button"*/}
        {/*          className={`${isShow ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"}, "whitespace-nowrap rounded-lg px-1.5 py-1 text-xs font-semibold shadow-sm"`}*/}
        {/*      >*/}
        {/*        열기*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        
        {/*  {isShow &&*/}
        {/*      <div onClick={resetOptions}*/}
        {/*           className="flex flex-row ml-5 cursor-pointer pcSizeHover:hover:text-indigo-500 text-gray-900 text-sm items-center"*/}
        {/*      >*/}
        {/*        <p>선택 초기화</p>*/}
        {/*        <RiLoopRightFill className="ml-2 h-5 w-5 text-gray-900 pcSizeHover:hover:text-indigo-500 mr-5"/>*/}
        {/*      </div>*/}
        {/*  }*/}
        {/*</div>*/}
        
        {/*/!* 체크테이블 영역 *!/*/}
        {/*{isShow &&*/}
        {/*    <div*/}
        {/*        className="mb-3 shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white overflow-auto">*/}
        {/*      <table className="min-w-full divide-y divide-gray-300 text-sm">*/}
        {/*        {FILTER_TABLE_DATA.map((data) => (*/}
        {/*            <thead key={data.title}>*/}
        {/*            <tr>*/}
        {/*              <th scope="col"*/}
        {/*                  className="py-2 font-semibold text-center sm:px-2 md:px-4 bg-gray-900 text-white whitespace-nowrap">*/}
        {/*                {data.title}*/}
        {/*              </th>*/}
        
        {/*              <td className="flex flex-wrap">*/}
        {/*                {data.options.map((option) => (*/}
        {/*                    <div key={option.id} className="py-1 sm:pl-6 lg:pl-4 text-gray-900">*/}
        {/*                      <div className="ml-3 flex h-6 items-center text-center">*/}
        {/*                        <input*/}
        {/*                            onChange={(e) => handleFilterCheck(e.target.checked, option.id)}*/}
        {/*                            checked={checkOptions.includes(option.id)}*/}
        {/*                            type="checkbox"*/}
        {/*                            className="h-4 w-4 border-gray-300 text-gray-900 focus:ring-gray-900"*/}
        {/*                        />*/}
        {/*                        <span className="ml-2">{getValueText(option.value)}</span>*/}
        {/*                      </div>*/}
        {/*                    </div>*/}
        {/*                ))}*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*            </thead>*/}
        {/*        ))}*/}
        {/*      </table>*/}
        {/*    </div>*/}
        {/*}*/}
      </>
  )
}
export default FilterCheckBoxTable;