import Header from "../components/Header";
import {Route, Routes} from "react-router-dom";
import {
  CUSTOM_PICK_PAGE,
  GAME_ANALYSIS_PAGE,
  HAPPY_PICK_PAGE,
  MAIN_PAGE,
  PROTO_GAME_PAGE, TOTO_PAGE
} from "../constants/page_constants";
import ProtoAnalysisPage from "./ProtoGamePage";
import GameAnalysisPage from "./GameAnalysisPage";
import HappyPickPage from "./HappyPickPage";
import CustomPickPage from "./CustomPickPage";
import TotoGamePage from "./TotoGamePage";


const MainPage = () => {
  return (
      <>
        <Header/>

        <div className="w-full h-[calc(100%-6rem)] flex-grow">
          <Routes>
            <Route path={PROTO_GAME_PAGE} element={<ProtoAnalysisPage/>}/>
            <Route path={TOTO_PAGE} element={<TotoGamePage/>}/>
            <Route path={GAME_ANALYSIS_PAGE} element={<GameAnalysisPage/>}/>
            <Route path={HAPPY_PICK_PAGE} element={<HappyPickPage/>}/>
            <Route path={CUSTOM_PICK_PAGE} element={<CustomPickPage/>}/>
          </Routes>
        </div>
      </>
  )
}
export default MainPage;