import soccerIcon from "../assets/images/icon_soccer.png";
import basketballIcon from "../assets/images/icon_basketball.png";
import volleyballIcon from "../assets/images/icon_volleyball.png";
import baseballIcon from "../assets/images/icon_baseball.png";
import golfIcon from "../assets/images/icon_golf.png";
import underOverIcon from "../assets/images/icon_uo.png";
import {accountInfo} from "../constants/constants";

// <-- 가로모드 세로모드 확인 -->
export const isLandscapeMode = () => {
  return window.matchMedia('(orientation: landscape)').matches;
};
window.addEventListener('resize', () => {
  // console.log('화면 변경 감지 : ',window.matchMedia('(orientation: landscape)').matches );
  isLandscapeMode()
});


// <-- 경기 카테고리에 따른 이미지, 지정색 가져오기 -->
export const getGameLogoImgURL = (category) => {
  category = category.toUpperCase();
  if (category === 'DEFAULT') return soccerIcon;
  if (category === 'SC') return soccerIcon;
  if (category === 'BK') return basketballIcon;
  if (category === 'VL') return volleyballIcon;
  if (category === 'BS') return baseballIcon;
  if (category === 'GF') return golfIcon;
  if (category === 'UO') return underOverIcon;
}

const _color_const_by_category = {
  dark: {
    all: 'bg-white',
    sc: 'bg-pink-300',
    bk: 'bg-orange-300',
    bs: 'bg-green-300',
    vl: 'bg-blue-300'
  },
  white: {
    all: 'bg-gray-900',
    sc: 'bg-pink-50 border border-pink-400',
    bk: 'bg-orange-50 border border-orange-400',
    bs: 'bg-green-50 border border-green-400',
    vl: 'bg-blue-50 border border-blue-400'
  }
}
export const getBackgroundColor = (category) => {
  let isDarkMode = accountInfo.isDarkMode();
  let mode = isDarkMode ? "dark" : "white";
  category = category.toLowerCase();

  try {
    let currentBackgroundStyle = _color_const_by_category[mode][category];

    if (currentBackgroundStyle)
      return currentBackgroundStyle;
  } catch {
  }

  // if (category === 'all') {
  //   return isDarkMode ? 'bg-white' : 'bg-gray-900';
  // }
  // if (category === 'SC') {
  //   return isDarkMode ? 'bg-pink-300' : 'bg-pink-50 border border-pink-300';
  // }
  // if (category === 'BK') {
  //   return isDarkMode ? 'bg-orange-300' : 'bg-orange-50 border border-orange-300';
  // }
  // if (category === 'BS') {
  //   return isDarkMode ? 'bg-green-300' : 'bg-green-50 border border-green-300';
  // }
  // if (category === 'VL') {
  //   return isDarkMode ? 'bg-blue-300' : 'bg-blue-50 border border-blue-300';
  // }
}

// 테일윈드 classNames 여러곳에 사용하기위해 빼놓음
export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}
