import axios from "axios";

const {REACT_APP_API_BASE_URL_TOTO} = process.env;

function _getBaseURL(url) {
  let currentPath = window.location.href;

  if (currentPath.includes("https://") && url.includes("http://")) {
    url = "https://" + new URL(currentPath).hostname + "/api3/";
  } else {
  }
  return url;
}


const fetcher = axios.create({
  baseURL: _getBaseURL(REACT_APP_API_BASE_URL_TOTO)
});

export function getFetcherEB() {
  return fetcher;
}

