import React, {useState, useEffect, useRef} from 'react';

// 디바운스 함수 구현
const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function getSmall(srcLarge) {
  if(srcLarge.includes('_sm.png')){
    return srcLarge.replace('_sm.png','_xs.png');
  }
  return srcLarge;
}


const LazyImage = ({ src, className, loading, alt,onError }) => {
  // const [currentSrc, setCurrentSrc] = useState(src);
  // const imgRef = useRef(null);
  // // const [isVisible, setIsVisible] = useState(false);
  //
  // useEffect(() => {
  //   // const observer = new IntersectionObserver(
  //   //     ([entry]) => setIsVisible(entry.isIntersecting),
  //   //     { threshold: 0.1 }
  //   // );
  //
  //   // if (imgRef.current) {
  //   //   observer.observe(imgRef.current);
  //   // }
  //   const handleScroll = debounce(() => {
  //     setCurrentSrc(src); // 스크롤이 멈췄을 때 큰 이미지로 전환
  //   }, 300); // 300ms 디바운스 타임 설정
  //
  //   const imgElement = imgRef.current;
  //
  //   const findScrollableParent = (element) => {
  //     let parent = element;
  //     while (parent) {
  //       const overflowY = window.getComputedStyle(parent).overflowY;
  //       if (overflowY === 'auto' || overflowY === 'scroll') {
  //         return parent; // 스크롤 가능한 부모를 찾으면 반환
  //       }
  //       parent = parent.parentElement;
  //     }
  //     return null;
  //   };
  //
  //   const scrollContainer =findScrollableParent(imgElement);
  //   // 스크롤 이벤트 리스너
  //
  //   if(scrollContainer) {
  //     scrollContainer.addEventListener('scroll', () => {
  //       console.log('onScrolling');
  //       setCurrentSrc(getSmall(src)); // 스크롤 중에는 작은 이미지 유지
  //       handleScroll(); // 스크롤이 멈추면 큰 이미지 로드
  //     });
  //
  //     return () => {
  //       // if (imgRef.current) {
  //       //   observer.unobserve(imgRef.current);
  //       // }
  //       scrollContainer.removeEventListener('scroll', handleScroll); // 클린업
  //     };
  //   }
  //   return ()=>{
  //     // if (imgRef.current) {
  //     //   observer.unobserve(imgRef.current);
  //     // }
  //   };
  // }, [src]);

  // return <img src={currentSrc}
  //             className={className} alt={alt} onError={onError} loading={loading} ref={imgRef}/>;
  // return <img src={isVisible ? currentSrc:'https://ebtest001.teamneverland.com/resources/img/empty.png'}
  //                                     className={className} alt={alt} onError={onError} loading={loading} ref={imgRef}/>;


  return <img src={src}
              className={className} alt={alt} onError={onError} loading={loading}/>;
};

export default LazyImage;