import WarningModal from "./WarningModal";
import ConfirmModal from "./ConfirmModal";
import LogoutModal from "./LogoutModal";
import {useNotifyModal} from "../../utils/useNotifyModal";
import SelectModal from "./SelectModal";

export const ModalHandler = ({initModalHandle}) => {
  const [isWarningModalOpen, setIsWarningModalOpen,
    isConfirmModalOpen, setIsConfirmModalOpen,
    isSelectModalOpen, setIsSelectModalOpen,
    modalMessageTitle, modalMessageContents, modalMenuJson,
    openWarningModal, openConfirmModal, openSelectModal] = useNotifyModal();

  initModalHandle.initModal(openConfirmModal, openWarningModal, openSelectModal);

  // onClick={() => OpenModal.openSelectModal("기기 수정", '기기 정보를 수정하시겠습니까?',
  //     {
  //       items:[
  //         {'text':'수정', params:'기기수정',
  //           'onClick':(arg, text, params) =>openSelectModalClick(arg, text, params)}
  //         ,
  //          {'text':'보여줄메뉴이름', params : {},
  //            'onClick':(arg, text, params) => { arg.isClose=false; }
  //          }
  //       ]
  //     })}

  return (
      <div>
        {isWarningModalOpen && (  // 경고 모달창
            <WarningModal
                messageTitle={modalMessageTitle}
                messageContents={modalMessageContents}
                setIsWarningModalOpen={setIsWarningModalOpen}
            />
        )}

        {isConfirmModalOpen && (  // 승인 모달창
            <ConfirmModal
                messageTitle={modalMessageTitle}
                messageContents={modalMessageContents}
                setIsConfirmModalOpen={setIsConfirmModalOpen}
            />
        )}

        {isSelectModalOpen && (  // 선택 모달창
            <SelectModal
                messageTitle={modalMessageTitle}
                messageContents={modalMessageContents}
                setIsSelectModalOpen={setIsSelectModalOpen}
                selectModalMenu={modalMenuJson}
            />
        )}
      </div>
  );
}

export class OpenModalHandle {
  openConfirmModal;
  openWarningModal;
  openSelectModal;

  initModal = (openConfirmModal, openWarningModal, openSelectModal) => {
    this.openConfirmModal = (title, contents) => {
      openConfirmModal(title, contents);
      return true;
    };
    this.openWarningModal = (title, contents) => {
      openWarningModal(title, contents);
      return false;
    };
    this.openSelectModal = (title, contents, menuJson) => {
      openSelectModal(title, contents, menuJson);
      return null;
    };
  };

  static create() {
    return new OpenModalHandle();
  }
}
