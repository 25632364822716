import React, {useEffect, useRef, useState} from "react";
import headData from '../assets/game_analysis_head_data.txt';
import {ModalHandler, OpenModalHandle} from "../components/modal/ModalHandler";
import {RiArrowLeftWideLine, RiCloseLine} from "react-icons/ri";

const GameAnalysisPage = ({iFrameUrl, setShowGameAnalysis}) => {
  const [selectedRate, setSelectedRate] = useState({value: null, index: null});
  const OpenModal = OpenModalHandle.create();

  // const isSelected = (value, index) => {  // 선택한 배당
  //   return selectedRate.value === value && selectedRate.index === index;
  // }

  const iframeRef = useRef(null);
  // const countRef = useRef(0);
  // const [iframeData, setIframeData] = useState("");
  // const [headContent, setHeadContent] = useState('');
  const [reloadDate, setReloadDate] = useState(new Date());
  //
  // // 수정할 헤드값 가져오기
  // // useEffect(() => {
  // //   fetch(headData)
  // //       .then((response) => response.text())
  // //       .then((text) => setHeadContent(text))
  // //       .catch((error) => console.error('Error loading the text file:', error));
  // // }, []);
  //
  //
  // useEffect(() => {
  //   setIframeData("<div> 정보를 가져오는 중...</div>");
  //   if (iFrameUrl != null) {
  //     //경기 분석 정보 받아오기
  //     fetch(iFrameUrl)
  //         .then((response) => {
  //           if (!response.ok) {
  //             throw new Error("Network response was not ok");
  //           }
  //           return response.text(); // HTML 데이터가 텍스트 형태로 반환됨
  //         })
  //         .then((data) => {
  //               let _newData = data.replace("<head>", "<head reload_date='" + reloadDate + "'>");
  //
  //               // _newData = cleanUpDocBeforeLoad(iFrameUrl, _newData);
  //
  //               const iframe = iframeRef.current;
  //
  //               iframe.onload = () => {
  //                 __loadGameAnaylysis(iFrameUrl);
  //               };
  //               setIframeData(_newData);
  //             }
  //         ) // HTML 데이터 상태에 저장
  //         .catch((error) => {
  //           console.error("Fetch error:", error);
  //           setIframeData("<div> 정보 확인 실패(" + error.message + ")</div>")
  //         });
  //   }
  //   return () => {
  //     const iframe = iframeRef.current;
  //     if (iframe) iframe.onload = null; // 정리
  //   };
  // }, [iFrameUrl, reloadDate]);
  //
  // // iframe data 업데이트 감지
  // useEffect(() => {
  //
  //   if (iframeRef != null && iframeData !== "") {
  //     if (iframeRef.current) {
  //
  //       const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
  //
  //       //기본 HTML 구조 작성
  //       iframeDoc.open();
  //       iframeDoc.write(iframeData);
  //       iframeDoc.close();
  //
  //       //
  //       const head = iframeDoc.querySelector("head");
  //       head.innerHTML = headContent;
  //
  //       // cleanUpDocByClassName("container");
  //     }
  //   }
  // }, [iframeData]);
  //
  // // function cleanUpDocBeforeLoad(iFrameUrl, _newData) {
  // //   // console.log('iFrameUrl='+iFrameUrl,_newData);
  // //   if (iFrameUrl.includes("aireport_win_vertical")) {
  // //     _newData = _newData.replace("<div class=\"container\" ", "<div class=\"container\" id=\"main_con\" style=\"display:none\" ");
  // //     _newData = _newData.replace("<div style=\"position:fixed;height:50px;width:100%;border:#ddd solid 3px;z-index:10;"
  // //         , "<div style=\"position:fixed;height:50px;width:100%;border:#ddd solid 3px;z-index:10;display:none;");
  // //
  // //     _newData = _newData.replaceAll("action/get_whoscore_", "score/action/get_whoscore_");
  // //     _newData = _newData.replaceAll("ajax/get_refresh_", "score/ajax/get_refresh_");
  // //   }
  // //   return _newData;
  // // }
  //
  // function cleanUpDocById(iframeDoc, elementId, makeEmpty) {
  //   const modal = iframeDoc.getElementById(elementId);
  //
  //
  //   if (modal) {
  //     if (makeEmpty) {
  //       modal.innerHTML = "";
  //       console.log("Target div is now cleared.[" + elementId + "]");
  //       return;
  //     }
  //     const targetDiv = modal.querySelector("div");
  //
  //     if (targetDiv) {
  //       targetDiv.style.display = "none"; // <div> 숨기기
  //       console.log("Target div is now hidden.[" + elementId + "]");
  //     }
  //   }
  // }
  //
  //
  // function getParameter(parameters, parameterKey, defaultValue) {
  //   parameterKey = parameterKey + "=";
  //   for (let p of parameters) {
  //     if (p.startsWith(parameterKey)) {
  //       return p.replace(parameterKey, "").trim();
  //     }
  //   }
  //   return defaultValue;
  // }
  //
  // function getGameIdFromDataScript(data, game_number) {
  //   let tokens = data.replaceAll('</tr>', '\0').split('\0');
  //   let temp;
  //   for (let t of tokens) {
  //     temp = t.trim().toLowerCase();
  //     if (temp.includes("id=\"game_number_") && (temp.includes(">" + game_number + "번</span></p>") || temp.includes(">" + game_number + "번<span "))) {
  //
  //       if (temp.includes("onclick=\"viewpreview('")) {
  //         temp = temp.split("onclick=\"viewpreview('")[1].split("'")[0];
  //         console.log("getGameIdFromDataScript[" + game_number + "]3=" + temp);
  //         return temp;
  //       }
  //     }
  //   }
  //   return -1;
  // }
  //
  //
  // function __loadGameAnaylysis(url) {
  //   const iframe = iframeRef.current;
  //   const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
  //   const iframeWin = iframe.contentWindow;
  //
  //   // iframe 내부 함수 preview 호출
  //   if (iframeWin && typeof iframeWin.viewPreview === "function") {
  //     cleanUpDocById(iframeDoc, "data_report", true);
  //     cleanUpDocById(iframeDoc, "lineChart", true);
  //     cleanUpDocById(iframeDoc, "lineChart2", true);
  //
  //     let parameters = url.split("?")[1].split('&');
  //     let title = getParameter(parameters, 'title');
  //     let game_id = getParameter(parameters, 'game_id');
  //     let game_tag = getParameter(parameters, 'game_tag');
  //     let game_number = getParameter(parameters, 'game_no');
  //     console.log(' iframeWin.viewPreview=' + game_id + "," + game_tag + "," + game_number);
  //
  //     if (url.includes('offline')) {
  //       game_id = getGameIdFromDataScript(iframeDoc.documentElement.outerHTML, game_number);
  //     }
  //     if (game_id === -1) {
  //       setIframeData("해당 경기의 분석정보는 준비 중입니다.[" + title + "]");
  //       return;
  //     }
  //     iframeWin.viewPreview(
  //         game_id,
  //         game_tag
  //     ); // iframe 내부 함수 실행
  //
  //     cleanUpDocById(iframeDoc, "preview_view_modal", false);
  //     // const observer = new MutationObserver((mutations) => {
  //     //   mutations.forEach((mutation) => {
  //     //     const modal = iframeDoc.getElementById("preview_view_modal");
  //     //
  //     //     if (modal) {
  //     //
  //     //       const targetDiv = modal.querySelector("div");
  //     //
  //     //       if (targetDiv) {
  //     //         targetDiv.style.display = "none"; // <div> 숨기기
  //     //         console.log("Target div is now hidden.");
  //     //         observer.disconnect(); // 더 이상 관찰하지 않음
  //     //       } else {
  //     //         // Target div가 없을 때 count 증가
  //     //         countRef.current += 1;
  //     //         console.log(`Target div not found. Attempt: ${countRef.current}`);
  //     //       }
  //     //     }
  //     //
  //     //     // 시도 횟수가 10을 초과하면 observer 중단
  //     //     if (countRef.current > 10) {
  //     //       console.log("Max attempts reached. Disconnecting observer.");
  //     //       observer.disconnect();
  //     //     }
  //     //   });
  //     // });
  //     //
  //     // observer.observe(iframeWin.document.body, {childList: true, subtree: true});
  //
  //
  //   } else {
  //     console.log("Function not found in iframe.");
  //   }
  // }

  // 경기 분석 실행 완료 후 이벤트 핸들러에 있는 preview 기능 실행

  const backOfIframe = () => {
    // console.log("iframeRef.current ->", iframeRef.current)
    // if (iframeRef.current) {
    //   iframeRef.current.contentWindow.location.reload();
    // }
    setReloadDate(new Date());
  }


  // if(iFrameUrl ==='' || iFrameUrl === null)
  return (
      <div className="w-[49.5rem] h-full flex flex-col justify-center bg-white rounded-lg">
        <ModalHandler initModalHandle={OpenModal}/>

        <main className="w-full h-full rounded-lg flex flex-col overflow-y-auto">
          {/*/!* <-- 경기 팀, 배당 정보 --> *!/*/}
          {/*<div className="flex-none">*/}
          {/*  <GameAnalysisInfoCard setSelectedRate={setSelectedRate} isSelected={isSelected}/>*/}
          {/*</div>*/}

          {/*/!* <-- 같은 배당 경기 분석 --> *!/*/}
          {/*<div className="flex-grow overflow-y-auto mt-5">*/}
          {/*  <img src={getImage('soccer_ground')} className="w-full h-auto"/>*/}
          {/*  {selectedRate && <SameGameRateInfoTable selectedRate={selectedRate}/>}*/}
          {/*</div>*/}

          {/* <-- 닫기버튼 --> */}
          <div className="flex justify-between px-2 py-3">
            <button onClick={backOfIframe}
                    className="flex flex-row items-center border border-gray-500 text-gray-500 pl-1 pr-3 rounded-lg shadow-md">
              <RiArrowLeftWideLine className="w-7 h-7"/>
              새로고침
            </button>

            <button onClick={() => setShowGameAnalysis(false)}>
              <RiCloseLine className="w-8 h-8"/>
            </button>
          </div>
          <iframe
              key={reloadDate}
              src={iFrameUrl}
              ref={iframeRef}
              title="Specific Site"
              style={{position: 'relative', width: '100%', height: '100%', border: 'none'}}
          />

        </main>
      </div>
  );
}

export default GameAnalysisPage;
