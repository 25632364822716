import React, {useEffect, useRef, useState} from "react";
import {TOTO_CATEGORY_FILTER_BUTTONS_DATA,} from "../constants/component_constants";
import {getGameLogoImgURL,} from "../utils/componentUtils";
import {accountInfo} from "../constants/constants";
import TotoGameCard from "../components/card/TotoGameCard";
import {GET_TOTO_GAME_INFO_API} from "../constants/api_constants";
import {getFetcherEB} from "../utils/fetcher_eb";


const TotoGamePage = () => {
  const [categoryOption, setCategoryOptionOption] = useState("sc");
  const [subMenuList, setSubMenuList] = useState([]);
  const [selectedSubMenuOption, setSelectedSubMenuOption] = useState('sc1');
  const [totoGameList, setTotoGameList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const thisYear = new Date().getFullYear();
  const selectedSubMenuOptionRef = useRef(selectedSubMenuOption);

  useEffect(() => {
    // 처음 호출
    getTotoGameInfo();

    // 60초마다 호출
    const intervalId = setInterval(() => {
      getTotoGameInfo();
    }, 60000);

    // 언마운트하면 중지
    return () => clearInterval(intervalId);
  }, [categoryOption]);

  useEffect(() => {
    // selectedSubMenuOption 변경될 때마다 ref 업데이트
    selectedSubMenuOptionRef.current = selectedSubMenuOption;
  }, [selectedSubMenuOption]);


  const getTotoGameInfo = () => {
    // 토토 경기정보 가져오기
    getFetcherEB().get(GET_TOTO_GAME_INFO_API + `?systemkey=wiztoto&year=${thisYear}&category=${categoryOption}`)
        .then(res => {
          const {memuList, contents} = res.data.result.gameTotoList.data;
          if (res.status === 200) {
            setTotoGameList(contents);
            setSubMenuList(memuList);
            setIsLoading(false);

            // 현재 선택된 메뉴가 menuList 없으면 첫 번째 메뉴로 설정
            const isCurrentMenuInList = memuList.some(menu => menu.value === selectedSubMenuOptionRef.current);

            if (!isCurrentMenuInList) {
              setSelectedSubMenuOption(memuList[0]?.value || null);
            }
          } else {
            console.log('정보 불러오기 실패', res.data);
          }
        }).catch(err => {
      console.log(err)
    })
  }

  function getCategoryTitle(name) {
    if (name.length < 10)
      return name;
    let tokens = name.split(' ');
    if (tokens.length > 3)
      return tokens[1] + ' ' + tokens[3];
    return name.substring(0, 9) + '...';
  }

  function isGameLive(value) {
    // 구매 가능한 경기인지 구분
    let liveCount = 0;
    let categoryItemCount = 0;
    for (const item of totoGameList) {
      if (item.categoryCode !== value) {
        continue;
      }
      categoryItemCount++;
      if (item.state === '경기전') {
        liveCount++;
      }
    }
    return categoryItemCount === liveCount;
  }

  if (isLoading) {
    return (<p> 정보를 가져오는 중입니다. </p>)
  }
  return (
      <div
          className="w-full h-[calc(100%-2rem)] flex flex-col items-center justify-center">
        {/* <------------ 상단 선택 버튼 ------------> */}
        <div
            className={`${accountInfo.isDarkMode() ? "text-white" : ""} w-full h-[3.5rem] flex flex-row font-bold pb-2 whitespace-nowrap items-center justify-center`}>
          {TOTO_CATEGORY_FILTER_BUTTONS_DATA.map((item, index) => {
            return (
                <div key={index} className="flex flex-col items-center">
                  <button
                      className={`${categoryOption === item.value ? (accountInfo.isDarkMode() ? "text-gray-900 bg-white" : "text-white bg-gray-900") + " rounded-full" : ""} 
                        text-lg px-7 mx-5 py-1`}
                      onClick={() => setCategoryOptionOption(item.value)}
                  >
                    {item.name}
                  </button>
                </div>
            )
          })}
        </div>

        <div className="w-[60rem] h-[calc(100%-3rem)] flex flex-row justify-center items-center px-5">
          {/* <------------ 서브메뉴 ------------> */}
          <div
              className={`${accountInfo.isDarkMode() ? "text-white" : ""} w-[18rem] h-full flex flex-col font-bold mt-6`}>
            {subMenuList?.map((subItem, subIndex) => (
                <div key={subIndex} className="flex flex-col items-start">
                  <button
                      className={`${selectedSubMenuOption === subItem.value ? "text-amber-900 border-amber-500 border-4" : "text-gray-900 border border-gray-200"} 
                      w-52 h-16 rounded-lg text-lg mb-4 shadow-md flex flex-row justify-start items-center pl-5 
                             ${isGameLive(subItem.value) ? "bg-white" : "bg-gray-500"}`}
                      onClick={() => setSelectedSubMenuOption(subItem.value)}
                  >
                    <img src={getGameLogoImgURL(categoryOption)} className="h-7 w-7 mr-2"/>

                    <div className="flex flex-col justify-start items-start ml-2">
                      <p>{getCategoryTitle(subItem.name)}</p>
                      {/*<p className="text-sm text-red-600">마감 1일전</p>*/}
                    </div>
                  </button>
                </div>
            ))}
          </div>

          {/* <------------ 경기정보 영역 ------------> */}
          <div className="w-[calc(100%-5rem)] h-full overflow-y-auto px-3 py-3">
            <TotoGameCard totoGameList={totoGameList} selectedSubMenuOption={selectedSubMenuOption}/>
          </div>
        </div>
      </div>
  );
}
export default TotoGamePage;
