import {useEffect, useState} from "react";
import {RiCloseLine, RiPushpinFill} from "react-icons/ri";
import neverLandGameData from "../../utils/NeverLandGameData";

const SelectedGameTable = () => {
  const [index, setIndex] = useState(0);

  const updateUI = () => {
    setIndex(prevState => prevState + 1);
  }
  const handleOnChanged = () => {
    updateUI();
  };

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
    }
  }, [])

  const handleCheckboxChange = (id, selectionKey) => {
    // 체크박스 onChange 핸들링
    neverLandGameData.toggleFixedAllotId(id, selectionKey);
  };

  if (neverLandGameData.selectedAllotData.length === 0)
    return null;

  return (
      <div className="w-full mb-4">
        <table className="min-w-full divide-y divide-gray-300 ring-1 ring-black ring-opacity-5 border border-gray-300">
          <thead className="bg-amber-700 text-white text-sm leading-6">
          <tr>
            <th colSpan={3} className="whitespace-nowrap py-1 px-2 font-semibold">
              <div className="flex">
                <span className="py-1 px-2 text-left">고정</span>
                <span className="py-1 px-3 text-left">경기</span>
                <span className="py-1 pl-10 text-right">홈팀</span>
              </div>
            </th>
            <th className="w-1/8 whitespace-nowrap py-1 px-2 font-semibold">
              예상
            </th>
            <th className="w-1/8 whitespace-nowrap py-1 px-2 font-semibold">
              배당률
            </th>
            <th className="w-1/8 whitespace-nowrap py-1 px-2 font-semibold">
            </th>
          </tr>
          </thead>

          <tbody className="divide-y divide-gray-300 text-sm text-gray-900 bg-white">
          {neverLandGameData.selectedAllotData?.map((game) =>
              <tr key={game.allotId + "|" + game.prediction}
                  className={`${neverLandGameData.isFixedAllotData(game.allotId, game.prediction) ? "bg-amber-100" : "bg-white"}`}>
                <td colSpan={3}>
                  <div onClick={() => handleCheckboxChange(game.allotId, game.prediction)}
                       className="py-1 flex items-center">
                    <div className="px-3">
                      {neverLandGameData.isFixedAllotData(game.allotId, game.prediction) ?
                          <RiPushpinFill className="h-5 w-5 text-red-600"/>
                          :
                          <input
                              type="checkbox"
                              checked={false}
                              className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onClick={(e) => e.stopPropagation()}
                              onChange={() => handleCheckboxChange(game.allotId, game.prediction)}
                          />
                      }
                    </div>
                    <span className="py-1 text-left pl-1 pr-3 w-14"> {game.gameNumber.replace("⒮", "S")} </span>
                    <span className="py-1 text-left"> {game.homeTeam} </span>
                  </div>
                </td>

                <td className="whitespace-nowrap py-1 px-2 text-center">
                  {neverLandGameData.getPrediction(game.gameNumber, game.prediction)}
                </td>
                <td className="whitespace-nowrap py-1 px-2 text-center">
                  {game.rate}
                </td>
                <td className="px-1">
                  <button onClick={() => neverLandGameData.toggleSelectItem(game.allotId, game.prediction)}
                  >
                    <RiCloseLine className="h-5 w-5 text-black"/>
                  </button>
                </td>
              </tr>
          )}
          </tbody>
        </table>
      </div>
  )
}
export default SelectedGameTable;