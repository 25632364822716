const teamProfileList = [
  // {teamName: '창원LG',leagueName:, category:'BK', profileURL: 'http://api.teamneverland.com/resources/img/profile/*.png'},
  // {teamName: 'KT소닉붐', leagueName:,category:'BK', profileURL: 'http://api.teamneverland.com/resources/img/profile/*.png'}
  {category: 'BK', profileURL: 'https://ebtest001.teamneverland.com/resources/img/profile/*.png'},
  {category: 'SC', profileURL: 'https://ebtest001.teamneverland.com/resources/img/profile/*.png'},
  {category: 'VL', profileURL: 'https://ebtest001.teamneverland.com/resources/img/profile/*.png'},
  {category: 'BS', profileURL: 'https://ebtest001.teamneverland.com/resources/img/profile/*.png'},
]

function matchingLeagueName(leagueName) {

  if (leagueName.length > 4) {
    // 와이즈랑 배트맨 리그 이름 안맞을때
    if (leagueName === "이탈FA컵") {
      return "이FA컵";
    }

    return leagueName;
  }

  return leagueName;
}

export function getTeamProfileImage(season, category, leagueName, teamName) {
  // <- 게임카드 팀 이미지 받아오기 ->
  leagueName = matchingLeagueName(leagueName);

  let dt = "1";

  if (teamName === '-') {
    for (let p of teamProfileList) {
      if (p.category === category) {
        if (p.profileURL.includes("/*.")) {
          return p.profileURL.replace("/*.", "/" + p.category + ".") + "?dt=" + dt;
        }
        return p.profileURL + "?dt=" + dt;
      }
    }

    return "https://s.yimg.com/iu/api/res/1.2/Sgp1vVLpVwRyLgIBEqTorA--~B/YXBwaWQ9c2hhcmVkO2ZpPWZpbGw7cT0xMDA7aD03Mjt3PTcy/https://s.yimg.com/cv/apiv2/default/20181022/500x500/bluejays_wbg.png";
  }

  for (let p of teamProfileList) {
    if (p.teamName && p.leagueName && p.teamName === teamName && p.leagueName === leagueName) {
      if (p.profileURL.includes("/*.")) {
        return p.profileURL.replace("/*.", "/" + p.leagueName + "_" + p.teamName + ".") + "?dt=" + dt;
      }
      return p.profileURL + "?dt=" + dt;
    }
  }
  for (let p of teamProfileList) {
    if (p.teamName && p.leagueName && p.teamName === teamName && p.leagueName === leagueName) {
      if (p.profileURL.includes("/*.")) {
        return p.profileURL.replace("/*.", "/" + p.leagueName + "_" + p.teamName + ".") + "?dt=" + dt;
      }
      return p.profileURL + "?dt=" + dt;
    }
  }
  let defaultProfile = 'https://ebtest001.teamneverland.com/resources/img/profile/*.png'

  return defaultProfile.replace("/*.", "/" + leagueName + "_" + teamName + "_sm.") + "?dt=" + dt;
}

