import {useNavigate, useParams} from 'react-router-dom';
import {useEffect} from "react";
import {LOGIN_API} from "../constants/api_constants";
import {ERROR_PAGE, PROTO_GAME_PAGE} from "../constants/page_constants";
import neverLandUtils from "../utils/NeverLandUtils";
import {
  DEVICE_CODE, SET_MONEY_BUTTON_LIST, STORE_CODE
} from "../constants/constants";
import {getFetcherEB} from "../utils/fetcher_eb";


const LoginPage = () => {
  // ❌❌❌ 지금 안씀 App.js 에서 로그인 처리하고있음 ❌❌❌

  const {id} = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    try {
      login();

    } catch {

    }
  }, [])

  const today= new Date();
  const login = () => {
    // 파라미터에서 아이디 받아와서 로그인하기
    if (id === null || id.trim().length === 0) {
      navigate(ERROR_PAGE + "/none_id" + "?dt=" + new Date().getTime());
      return;
    }
    getFetcherEB().get(LOGIN_API + `?codeName=${id}` + "&dt=" + new Date().getTime())
        .then(res => {
          const {responseIsSuccess, responseResultDescription} = res.data;
          if (responseIsSuccess) {
            neverLandUtils.commons.save(STORE_CODE, res.data.storeCodeName);
            neverLandUtils.commons.save(DEVICE_CODE, res.data.machineCodeName);
            neverLandUtils.commons.save(SET_MONEY_BUTTON_LIST, res.data.storeIsButtonActiveStatusList);
            navigate(PROTO_GAME_PAGE);
          } else {
            console.log(responseResultDescription);
             navigate(ERROR_PAGE + `/${id}` + "?dt=" + new Date().getTime());
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .catch((err) => console.error(err));
  }

  return (
      <>
        <p className="text-2xl text-white text-center">로그인중입니다.</p>
        <p className="text-2xl text-white text-center">ID: {id}</p>
      </>
  )
}
export default LoginPage;