import {useEffect, useState} from "react";
import React from 'react';
import {classNames, getBackgroundColor, getGameLogoImgURL} from "../../utils/componentUtils";
import neverLandGameData from "../../utils/NeverLandGameData";
import {getTeamProfileImage} from "../../utils/TeamProfiles";
import {RiArrowDownSFill, RiArrowUpSFill, RiLock2Fill} from "react-icons/ri";
import LazyImage from "../LazyImage";
import {ModalHandler, OpenModalHandle} from "../modal/ModalHandler";
import {GET_GAME_ANALYSIS_URL_API} from "../../constants/api_constants";
import {getFetcherAnalysis} from "../../utils/fetcher_gameAnlysis";
import neverLandUtils from "../../utils/NeverLandUtils";
import {DEVICE_CODE} from "../../constants/constants";


const monitoringInterval = 10000; // 업데이트할 시간 (ex : 10초마다)
const monitoringTargetMin = 20; // 화면에 띄울 마감시간 기준 (ex: 마감20분 전부터 띄우기)
const ProtoGameCard = ({categoryOption, setShowGameAnalysis, setIFrameUrl}) => {
  const [refreshDate, setRefreshDate] = useState(new Date());
  const OpenModal = OpenModalHandle.create();
  const updateUI = () => {
    setRefreshDate(new Date());
  }
  const handleOnChanged = () => {
    updateUI();
  };

  // <-- 현재 시간 & 마감 시간 비교하고 20초마다 업데이트 -->
  const updateClosingTimes = () => {
    let filteredGameData = neverLandGameData.getFilteredGameData();
    let prevClosingTimes = '';
    let newClosingTimes = '';
    let nowDate = new Date();
    let preDate = new Date(nowDate.getTime() - monitoringInterval);
    let _monitoringTargetMin = monitoringTargetMin + 1;
    // 업데이트전 시간 데이터
    for (let game of filteredGameData) {
      if (game.closingDate !== "-") {
        let min = compareDate(preDate, game.closingDate);
        if (min < 0)
          continue;
        if (_monitoringTargetMin > min) {
          if (prevClosingTimes.length > 0) {
            prevClosingTimes += ',';
          }
          prevClosingTimes += (game.dataId + '-' + min);
        }
      }
    }
    // 업데이트 후 데이터
    for (let game of filteredGameData) {
      if (game.closingDate !== "-") {
        let min = compareDate(nowDate, game.closingDate);
        if (min < 0)
          continue;
        if (_monitoringTargetMin > min) {
          if (newClosingTimes.length > 0) {
            newClosingTimes += ',';
          }
          newClosingTimes += (game.dataId + '-' + min);
        }
      }
    }
    // 업데이트 시간에 변동 없으면 return;
    if (newClosingTimes === prevClosingTimes)
      return;
    //  console.log('updateClosingTimes2 :: ' + prevClosingTimes + " -> " + newClosingTimes);

    setRefreshDate(nowDate);
  };
  const compareDate = (nowDate, gameDate) => {
    // 현재 시간과 마감 시간 비교
    const _gameDate = new Date(gameDate)
    let diffMinute = (_gameDate - nowDate) / (1000 * 60); // 분으로 변환해서 산출
    return Math.ceil(diffMinute); // 소수점 올림
  }

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    neverLandGameData.loadGameDataList("game_card");
    updateClosingTimes(); // 마감시간 초기 설정
    const interval = setInterval(updateClosingTimes, monitoringInterval); // 마감시간 10초마다 업데이트
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
      clearInterval(interval);  // 언마운트시 인터벌 중지
    }
  }, [])

  const _toggleSelectItem = (parentGameInfo, thisGameData, id, key) => {
    // 조건부로 버튼 안먹히게
    if (parentGameInfo.state !== "발매중")
      return;
    if (key === "d" && // 무승부일때
        ((thisGameData.extraText !== 'H' || thisGameData.draw === '0.00')
            && thisGameData.extraText !== '무'
            && thisGameData.extraText !== '⑤'
            && thisGameData.extraText !== '①'
        )
    )
      return;
    if (neverLandGameData.toggleSelectItem(id, key)) {
      // 경기조합 1000개 넘어가면 안눌러지게
      if (neverLandGameData.ticketData.length > 1000) {
        OpenModal.openWarningModal('선택 불가', '조합 1000개 이상은 선택 불가합니다.');
        neverLandGameData.toggleSelectItem(id, key);
      }
      return;
    }
  }

  function isLockState(buttonData, selection) {
    // 버튼 잠금 여부 결정
    let selectionFlag = 0;
    // 배당이 1.0일때 버튼 잠그기
    if (selection === 'w') {
      if (buttonData.win === '1.00') {
        return true;
      }
      selectionFlag = 1 << 0;
    }
    if (selection === 'd') {
      if (buttonData.draw === '1.00') {
        return true;
      }
      selectionFlag = 1 << 1;
    }
    if (selection === 'l') {
      if (buttonData.lose === '1.00') {
        return true;
      }
      selectionFlag = 1 << 2;
    }

    let lockState = buttonData.lockState !== null ? parseInt(buttonData.lockState, 10) : 0;
    let lockStateByAdmin = buttonData.lockStateByAdmin !== null ? parseInt(buttonData.lockStateByAdmin, 10) : 0;

    if (lockState & selectionFlag) {
      return true;
    }
    if (lockStateByAdmin & selectionFlag) {
      return true;
    }
    return false;
  }

  const handleChangedRate = (changeRate) => {
    // 배당정보 변경 정보 화면에 띄우기 (현재는 화살표만, 숫자는 차후)
    let _changeRate = Number(changeRate);
    if (_changeRate === 0) return;
    if (_changeRate > 0)
      return (
          <div className="flex flex-row items-center justify-center -mr-3">
            <RiArrowUpSFill
                aria-hidden="true"
                className="h-5 w-auto text-red-600"
            />
            {/*<p className="text-red-600 text-xs">{changeRate}</p>*/}
          </div>
      );
    if (_changeRate < 0)
      return (
          <div className="flex flex-row items-center justify-center -mr-3">
            <RiArrowDownSFill
                aria-hidden="true"
                className="h-5 w-auto text-blue-600"
            />
            {/*<p className="text-blue-600 text-xs">{changeRate}</p>*/}
          </div>
      )
    return null;
  }

  const getGameAnalysisUrlList = (id,gameNo,title) => {
    //경기 분석 정보 받아오기
    getFetcherAnalysis().get(GET_GAME_ANALYSIS_URL_API + `?dataId=${id}&dataDetailType=${"프리뷰"}&deviceCode=${neverLandUtils.commons.load(DEVICE_CODE)}`)
        .then(res => {
          const {responseIsSuccess, linkUrl} = res.data;
          if (responseIsSuccess) {
            if (linkUrl) {
              // setIFrameUrl(linkUrl+"&status=online&game_no="+gameNo+"&title="+title);
              setIFrameUrl(linkUrl);
            } else {
              // let urlText = '/score/aireport_win_vertical.php?game_id=137219&game_tag=1&status=offline';
              // let urlText = '';

              // if(window.location.href.includes('localhost:')){
              //   urlText='http://score.bjscore.com'+urlText;
              // }
              setIFrameUrl('');
            }
            setShowGameAnalysis(true);
          } else {
            ModalHandler.openWarningModal(res.status, '데이터를 불러오는데 실패하였습니다.');
          }
        }).catch(err => console.log(err));
  }

  const seasonCode = '2024';
  let nowDate = new Date();

  return (
      <div>
        <ModalHandler initModalHandle={OpenModal}/>

        <ul role="list" className="flex flex-col justify-center items-center gap-4">
          {neverLandGameData.getFilteredGameData()?.map((game, index) => {
            // 데이터 유효성 검사
            if (game.closingDate === "-") {
              return null;
            }
            // 선택한 경기 카테고리에 따라 필터해서 띄우기
            if (categoryOption !== "all") {
              if (game.category !== categoryOption) return;
            }

            if (game.state !== '발매중') return;

            return (
                <React.Fragment key={index}>
                  <li className="col-span-1 w-full rounded-lg bg-white text-center shadow-md border border-gray-200 overflow-hidden py-2">
                    <div className="flex flex-1 flex-row justify-center">
                      {/* <- 경기번호 버튼 -> */}
                      <div className="flex flex-col justify-center">
                        {game.data?.map((buttonData) => {
                          if (game.state === "null") return null;
                          return (
                              <div key={buttonData.allotId}
                                   className="game-card-btn-custom game-number">
                                <p>{buttonData.gameNo.replace("ⓢ", "")}</p>
                                <p className="text-yellow-300 font-semibold ml-1">{buttonData.gameNo.includes("ⓢ") ? "ⓢ" : ""}</p>
                              </div>
                          )
                        })}
                      </div>

                      {/* <-- 경기 정보 --> */}
                      <div className="w-1/5 flex flex-col items-center justify-center mx-1">
                        {compareDate(nowDate, game.closingDate) <= monitoringTargetMin &&
                            <span className="bg-rose-700 text-white py-0.5 px-2 rounded-lg mb-3 text-sm">
                              마감 {compareDate(nowDate, game.closingDate)}분 전
                            </span>
                        }

                        {/* 경기 리그 */}
                        {game.isDoubleHeader && // 더블헤더일때 (같은날에 같은 홈팀 vs 원정팀 두번 경기)
                            <span
                                className="inline-flex items-center rounded-full px-2 py-1 text-sm bg-fuchsia-600 text-white whitespace-nowrap mb-2">
                            더블헤더
                          </span>
                        }
                        <div
                            className={classNames("flex flex-row items-center w-24 justify-center rounded-lg", getBackgroundColor(game.category))}>
                          <img src={getGameLogoImgURL(game.category)} className="h-4 w-4 mr-2"/>
                          <p className="font-semibold">{game.leagueName}</p>
                        </div>

                        {/* 경기 시간 */}
                        <p className="text-sm text-gray-500 mt-3 mb-3 tracking-tighter">{neverLandGameData.formatDate(game.closingDate)}</p>

                        {/* 경기 분석 */}
                        {game.isPreviewData &&
                        <button className="button-style bg-teal-500 text-sm px-4 py-1"
                                onClick={() => getGameAnalysisUrlList(game.dataId, game.data[0].gameNo.replace("ⓢ", ""),game.homeTeam+" vs "+game.awayTeam)}>
                          경기 분석
                        </button>
                        }
                      </div>

                      {/* <-- 홈 팀 정보 --> */}
                      <div className="w-16 font-semibold flex flex-col justify-center items-center">
                        <p>{game.homeTeam}</p>
                        <LazyImage className="w-9 h-auto pt-3" loading="lazy"
                                   src={getTeamProfileImage(seasonCode, game.category, game.leagueName, game.homeTeam)}
                                   onError={(e) => {
                                     e.target.src = getTeamProfileImage('-', game.category, game.leagueName, '-');
                                   }}
                                   alt="홈 팀"/>
                      </div>

                      {/* <-- 경기 선택 버튼 --> */}
                      <table className="flex flex-row justify-center w-[15rem] ml-1 mr-2">
                        <tbody>
                        {game.data?.map((buttonData) => {
                          if (game.state === "null") return null;
                          return (
                              <tr key={buttonData.allotId}>

                                {/* <- 승 버튼 -> */}
                                <td className="w-full">
                                  <div className="flex w-full">
                                    {buttonData.win === "0.00" ?
                                        <div className='game-card-btn-custom'> - </div>
                                        :
                                        <button
                                            disabled={isLockState(buttonData, 'w')}
                                            onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'w')}
                                            className="flex-1 relative"
                                        >
                                          {/*잠금 여부*/}
                                          {isLockState(buttonData, 'w') && <RiLock2Fill
                                              className="h-6 w-auto text-white bg-zinc-600 p-1 rounded-full absolute top-0 left-0.5 z-10"/>}
                                          <div className={`${isLockState(buttonData, 'w') ? 'lock-state' :
                                              neverLandGameData.isSelected(buttonData.allotId, 'w') ? 'selected-state' : ''} flex flex-col game-card-btn-custom
                                            `}
                                          >
                                            <p>{buttonData.extraText === "U/O" ? "U" : buttonData.extraText === "SUM" ? "홀" : "승"}</p>
                                            <div className="flex flex-row items-center w-full justify-center">
                                              <p>{buttonData.win}</p>
                                              {/* 배당 변동 여부 */}
                                              {handleChangedRate(buttonData.changedWinValue)}
                                            </div>
                                            {/*경기 배당*/}
                                          </div>
                                        </button>
                                    }
                                  </div>
                                </td>

                                {/* <- 무 버튼 -> */}
                                <td className="w-full">
                                  <div className="flex w-full">
                                    {(parseFloat(buttonData.draw) === 0 && buttonData.extra === '0.0' && buttonData.extraText !== 'SUM' || buttonData.extraText === null) ?
                                        // 무 배당이 0.00 이면 버튼 - 으로 표시
                                        <div className='game-card-btn-custom'> - </div>
                                        :
                                        ((buttonData.extraText == null || buttonData.extraText === "null") ? "-" :
                                                <button
                                                    disabled={isLockState(buttonData, 'd') ||
                                                        // 핸디면서 배당 0 일때 눌러져서 조건 추가함
                                                        (buttonData.extraText === 'H' && parseFloat(buttonData.draw) === 0)
                                                    }
                                                    onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'd')}
                                                    className="flex-1 relative"
                                                >
                                                  {isLockState(buttonData, 'd') && <RiLock2Fill
                                                      className="h-6 w-auto text-white bg-zinc-600 p-1 rounded-full absolute top-0 left-0.5 z-10"/>}
                                                  <div className={classNames(
                                                      isLockState(buttonData, 'd') ? 'lock-state' :
                                                          neverLandGameData.isSelected(buttonData.allotId, 'd') ? 'selected-state' : '',
                                                      // 핸디가 아니거나 배당이 0이면서 무, ①, ⑤이 아닌 경우
                                                      ((buttonData.extraText !== 'H' || parseFloat(buttonData.draw) === 0)
                                                          && buttonData.extraText !== '무'
                                                          && buttonData.extraText !== '⑤'
                                                          && buttonData.extraText !== '①') ? 'disabled-state' : '', 'game-card-btn-custom text-xs'
                                                  )}>
                                                    {
                                                      // 무, 승일패(①), 승오패(⑤)인 경우
                                                      (buttonData.extraText === '무' || buttonData.extraText === '①' || buttonData.extraText === '⑤') ?
                                                          <div className="flex flex-col text-sm">
                                                            <p>{buttonData.extraText}</p>
                                                            <div
                                                                className="flex flex-row items-center w-full justify-center">
                                                              {/*경기 배당*/}
                                                              <p>{buttonData.draw}</p>
                                                              {/* 배당 변동 여부 */}
                                                              {handleChangedRate(buttonData.changedDrawValue)}
                                                            </div>
                                                          </div>

                                                          // 핸디면서 배당이 0이 아닐때
                                                          : buttonData.extraText === 'H' && parseFloat(buttonData.draw) !== 0 ?
                                                              <div className="whitespace-nowrap">
                                                                <p>{"무 " + buttonData.draw}</p>
                                                                <div
                                                                    className="flex flex-row items-center w-full justify-center">
                                                                  <p>{buttonData.extraText + (buttonData.extra > 0 ? "+" : " ") + buttonData.extra}</p>
                                                                  {/* 배당 변동 여부 */}
                                                                  {handleChangedRate(buttonData.changedDrawValue)}
                                                                </div>
                                                              </div>

                                                              // SUM 일때
                                                              : buttonData.extraText === 'SUM' ?
                                                                  <p className="disabled-state">
                                                                    {buttonData.extraText}
                                                                  </p>

                                                                  : <p>
                                                                    {buttonData.extraText}<br/>
                                                                    {((buttonData.extraText === 'H' && buttonData.extra > 0) ? "+" : "") + buttonData.extra}
                                                                  </p>
                                                    }
                                                  </div>
                                                </button>
                                        )}
                                  </div>
                                </td>

                                {/* <- 패 버튼 -> */}
                                <td className="w-full">
                                  <div className="flex w-full">
                                    {buttonData.lose === "0.00" ?
                                        <div
                                            className='game-card-btn-custom'>
                                          -
                                        </div>
                                        : <button
                                            disabled={isLockState(buttonData, 'l')}
                                            className="flex-1 relative"
                                            onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'l')}
                                        >
                                          {isLockState(buttonData, 'l') && <RiLock2Fill
                                              className="h-6 w-auto text-white bg-zinc-600 p-1 rounded-full absolute top-0 left-0.5 z-10"/>}
                                          <div className={`${isLockState(buttonData, 'l') ? 'lock-state' :
                                              neverLandGameData.isSelected(buttonData.allotId, 'l') ? 'selected-state' : ''} game-card-btn-custom
                                            `}
                                          >
                                            <div>
                                              <p>{buttonData.extraText === "U/O" ? "O" : buttonData.extraText === "SUM" ? "짝" : "패"}</p>
                                              <div className="flex flex-row items-center w-full justify-center">
                                                {/*경기 배당*/}
                                                <p>{buttonData.lose}</p>
                                                {/* 배당 변동 여부 */}
                                                {handleChangedRate(buttonData.changedLoseValue)}
                                              </div>
                                            </div>
                                          </div>
                                        </button>
                                    }
                                  </div>
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>

                      {/* <-- 원정 팀 정보 --> */}
                      <div
                          className="w-16 font-semibold flex flex-col justify-center items-center mr-3">
                        <p>{game.awayTeam} </p>
                        <LazyImage className="w-9 h-auto pt-3" loading="lazy"
                                   src={getTeamProfileImage(seasonCode, game.category, game.leagueName, game.awayTeam)}
                                   onError={(e) => {
                                     e.target.src = getTeamProfileImage('-', game.category, game.leagueName, '-');
                                   }}
                                   alt="원정 팀"/>
                      </div>
                    </div>
                  </li>
                </React.Fragment>
            );
          })}
        </ul>
      </div>
  )
}
export default ProtoGameCard;
