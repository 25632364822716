import {useEffect, useState} from "react";
import React from 'react';
import {classNames, getBackgroundColor, getGameLogoImgURL} from "../../utils/componentUtils";
import neverLandGameData from "../../utils/NeverLandGameData";
import {getTeamProfileImage} from "../../utils/TeamProfiles";
import {RiLock2Fill} from "react-icons/ri";
import LazyImage from "../LazyImage";
import {ModalHandler, OpenModalHandle} from "../modal/ModalHandler";
import {_totoGameData} from "../../_dummyData";

const TotoGameCard = ({totoGameList, selectedSubMenuOption}) => {
  const getResultColor = (result) => {
    if (result === "홈승") return 'bg-blue-800';
    if (result === "홈패") return 'bg-red-700';
    if (result === "무승부" || result === "1" || result === "5") return 'bg-gray-600';
    if (result === "취소") return 'bg-amber-600';
    return 'bg-red-700';  // 골프 결과
  }

  const handleShowResult = (category, fiducialValue, result) => {
    // 경기 결과 띄우기
    if (result === null) {
      return null;
    }
    if (result === "null") {
      return null;
    }
    if (category === "UO") { // 언더오버 일때
      return (
          <div
              className="w-full flex flex-row justify-center items-center font-normal text-white whitespace-nowrap mt-2">
            <p className={`${result.homeResult === "O" ? "bg-red-600" : "bg-green-600"} px-3  rounded-lg`}>{result.homeResult}</p>
            <p className="w-28 bg-gray-500 rounded-lg mx-10">기준값 : {fiducialValue}</p>
            <p className={`${result.awayResult === "O" ? "bg-red-600" : "bg-green-600"} px-3  rounded-lg`}>{result.awayResult}</p>
          </div>
      )
    }
    return (
        <p className={`font-normal rounded-lg text-white px-5 whitespace-nowrap ${getResultColor(result)}`}>{result}</p>)
  }

  const seasonCode = '2024';
  let nowDate = new Date();


  return (
      <div>
        <ul role="list" className="flex flex-col justify-center items-center gap-4">
          {totoGameList?.map((game, index) => {
            // {_totoGameData.contents?.map((game, index) => {
            // 데이터 유효성 검사
            // if (game.closingDate === "-") {
            //   return null;
            // }

            // 선택한 메뉴에 해당하는 데이터만 띄우기
            if (selectedSubMenuOption !== game.categoryCode) return;

            return (
                <React.Fragment key={index}>
                  <li
                      className="w-full h-full rounded-lg bg-white text-center shadow-md border border-gray-200 py-1 flex flex-1 flex-row justify-between items-center px-24">
                    {/* <-- 경기 정보 --> */}
                    <div className="flex flex-col items-center justify-center">
                      {/*경기 번호*/}
                      <div className="game-card-btn-custom game-number w-14 h-6 m-0">
                        <p> {game.gameNo} </p>
                      </div>

                      {/* 경기 리그 */}
                      <div
                          className="flex flex-row items-center justify-center rounded-lg border border-gray-600 my-2 px-3 whitespace-nowrap">
                        <p className="font-semibold">{game.leagueName}</p>
                      </div>

                      {/* 경기 시간 */}
                      <p className="text-gray-500">{game.gameplayDate}</p>
                    </div>

                    {/* <-- 팀, 스코어, 결과 정보 --> */}
                    <div
                        className={`${game.category === "GF" ? "flex-row" : "flex-col"} w-2/3 flex  justify-between items-center`}>
                      {game.category === "GF" ?
                          // 골프일때
                          <div className="flex flex-row font-bold text-xl ml-5 whitespace-nowrap">
                            <p>대상선수 : </p>
                            <p className="px-2">{game.player}</p>
                          </div>
                          :
                          <div className="w-full flex flex-row items-center whitespace-nowrap">
                            {/* 홈 팀 */}
                            <div className="w-1/3 font-semibold flex flex-col justify-center items-center">
                              <p>{game.homeTeam}</p>
                              <LazyImage className="w-9 h-auto pt-3" loading="lazy"
                                         src={getTeamProfileImage(seasonCode, game.category, game.leagueName, game.homeTeam)}
                                         onError={(e) => e.target.src = getTeamProfileImage('-', game.category, game.leagueName, '-')}
                                         alt="홈 팀"/>
                            </div>

                            {/* 점수, 진행 현황 */}
                            <div className="w-32 flex flex-col font-bold mx-3 justify-center items-center px-5">
                              <p>VS</p>

                              {game.state === '경기종료' ?
                                  // 경기 종료 -> 스코어 띄우기
                                  // 이긴쪽이거나 언더오버일때 기준값 이상이면 빨간색으로
                                  <div className="flex flex-row text-3xl py-1">
                                    <p className={`${game.resultScript === '홈승' || (game.category === "UO" && game.fiducialValue < game.homeScore) ? "text-red-600" : "text-gray-600"}`}>
                                      {game.homeScore}
                                    </p>

                                    <p className="px-3">:</p>

                                    <p className={`${game.resultScript === '홈패' || (game.category === "UO" && game.fiducialValue < game.awayScore) ? "text-red-600" : "text-gray-600"}`}>
                                      {game.awayScore}
                                    </p>
                                  </div>

                                  :
                                  // 경기전, 경기중, 경기취소
                                  <p className={`${game.state === '경기취소' ? "border border-red-500 text-red-500 px-3" : "border border-gray-600 text-gray-600 px-5"} bg-white font-normal rounded-lg text-xl mt-2`}>
                                    {game.state}
                                  </p>
                              }
                            </div>

                            {/* 원정 팀 */}
                            <div
                                className="w-1/3 font-semibold flex flex-col justify-center items-center">
                              <p>{game.awayTeam} </p>
                              <LazyImage className="w-9 h-auto pt-3" loading="lazy"
                                         src={getTeamProfileImage(seasonCode, game.category, game.leagueName, game.awayTeam)}
                                         onError={(e) => e.target.src = getTeamProfileImage('-', game.category, game.leagueName, '-')}
                                         alt="원정 팀"/>
                            </div>
                          </div>
                      }

                      {/* 경기결과 */}
                      {game.state === '경기종료' && game.resultScript && handleShowResult(game.category, game.fiducialValue, game.resultScript)}
                    </div>
                  </li>
                </React.Fragment>
            );
          })}
        </ul>
      </div>
  )
}
export default TotoGameCard;
